<template>

    
  <div class="inner-stage leads-stage" style=" position:relative">
    <v-navigation-drawer
      v-model="drawerOpen"
      fixed
      class="fdraw"
      width="350"
      temporary
    >
    <FilterDrawer :isOpen="drawerOpen" @close="drawerOpen = false, goSearch()" :filters="filters" @updateFilter="updateFilter" />
    </v-navigation-drawer>
    
      <v-dialog v-model="leadOpen" width="1200px">
        <EntryPopup :direction="direction" @close="leadOpen = false" @getLeads="goSearch" :entry="activeLead" />
      </v-dialog>
    
    
    <!-- <transition enter-active-class="fadeInUp" leave-active-class="fadeOut">
      <div v-if="!isLoaded" style="opacity: 0; position:absolute;z-index:1000; width:calc(100% - 250px); left:250px; top:70px; height:calc(100vh - 70px);"  class="loaderContainer animated-fast">
        <div  class="wrapper">
          <div class="wrapper-cell">
            
            <div class="text">
              <div class="text-line" style="height:50px"></div>
              <div class="text-line" style="max-width:100px;margin-top:40px;"></div>
              <div class="text-line"></div>
              <div class="text-line"></div>
            </div>
          </div>
          <div class="wrapper-cell">
            
            <div class="text">
              <div class="text-line"></div>
              <div class="text-line"></div>
              <div class="text-line"></div>
              <div class="text-line"></div>
            </div>
          </div>
          <div class="wrapper-cell">
            
            <div class="text">
              <div class="text-line"></div>
              <div class="text-line"></div>
              <div class="text-line"></div>
              <div class="text-line"></div>
            </div>
          </div>
          <div class="wrapper-cell">
           
            <div class="text">
              <div class="text-line"></div>
              <div class="text-line"></div>
              <div class="text-line"></div>
              <div class="text-line"></div>
            </div>
          </div>
        </div>
      </div>
    </transition> -->
    <div style="padding:15px; position:relative">
    <div :style="isLoaded ? `` :`opacity:.4; pointer-events:none`" class="search-container">
      <v-tooltip top>
        <template v-slot:activator="{on}">
          <v-btn v-on="on" @click="clearFilters" icon text><i class="fal fa-times"></i> </v-btn></template>
          <span>Clear Filters</span>
      </v-tooltip>
      
      <v-btn depressed small style="margin-right:10px; " @click="drawerOpen = !drawerOpen">
        <i class="fal fa-filter"></i> &nbsp; Filters  </v-btn>
      <!-- <v-select
        class="fieldSelect"
        v-model="searchField"
        style="max-width:200px; margin-right:10px;"
        item-text="label"
        item-value="value"
        :items="searchColumns"
      ></v-select>-->
      <v-text-field
        v-model="term"
        @keyup.enter.native="goSearch"
        label="Search"
        fullwidth
        style="width:100%;"
        :color="appColor"
      >
      
        <template v-slot:prepend-inner>
          <i class="fa fa-search"></i>
        </template>
      </v-text-field>
      <v-menu
        v-model="filterMenu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn color="indigo" style="display:none;" dark v-on="on">
            <i class="fal fa-filter"></i>
          </v-btn>
        </template>

        <v-card style="width: 400px">
          <div style="padding: 15px">
            <v-select
              v-model="filter"
              item-text="label"
              item-value="value"
              @change="getDataType"
              :items="columns"
              label="Column"
            ></v-select>
            <div style="display: flex" v-if="activeDataType == 'number'">
              <v-select
                v-model="filterOperator"
                label="Operator"
                :items="['between', 'greater than', 'less than']"
              ></v-select>
              <v-text-field
                v-if="filterOperator == 'between'"
                style="margin-left: 10px"
                label="min"
                v-model="filterMin"
              ></v-text-field>
              <v-text-field
                v-if="filterOperator == 'between'"
                style="margin-left: 10px"
                label="max"
                v-model="filterMax"
              ></v-text-field>
              <v-text-field
                v-if="filterOperator != 'between'"
                style="margin-left: 10px"
                label="value"
                v-model="filterValue"
              ></v-text-field>
            </div>
            <div style="display: flex" v-if="activeDataType == 'string'">
              <v-select
                v-model="filterOperator"
                label="Operator"
                :items="['contains', 'excludes']"
              ></v-select>
              <v-text-field
                style="margin-left: 10px"
                label="Value"
                v-model="filterValue"
              ></v-text-field>
            </div>
            <div style="display: flex" v-if="activeDataType == 'bool'">
              <v-select
                v-model="filterOperator"
                label="Operator"
                :items="['equals']"
              ></v-select>
              <v-select
                v-model="filterValue"
                label="Value"
                :items="['true', 'false']"
              ></v-select>
            </div>
            <div style="display: flex" v-if="activeDataType == 'date'">
              <v-select
                v-model="filterOperator"
                label="Operator"
                :items="['before', 'after']"
              ></v-select>
              <v-menu
                v-model="pickerOpen"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filterStartDate"
                    label="Payment Start Date"
                    style="max-width: 200px"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filterStartDate"
                  light
                  @input="pickerOpen = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <div style="display: flex; align-items: center">
              <v-select
                v-model="filterSort"
                label="Sorting"
                style="max-width: 100px"
                :items="['asc', 'dec']"
              ></v-select>
            </div>
            <div style="color: #ff0000; font-size: 10pt" v-if="filterError">
              {{ filterErrorMessage }}
            </div>
          </div>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn flat @click="filterMenu = false">Cancel</v-btn>
            <v-btn color="primary" flat @click="submitFilter">Save</v-btn>
            <v-btn color="primary" flat @click="clearFilter"
              >Clear Filter</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>
    <div class="filter-tags">
      <v-btn  text :color="appColor" @click="exportToCsv">export to csv</v-btn>
      <div class="filter-tag" v-for="(item,i) in $route.query.status" :key="i" :class="item"><i @click="removeTag(item)" class="fal fa-times"></i> {{ item }}</div>
    </div>
    <div :style="isLoaded ? `` :`opacity:.4; pointer-events:none`" class="total-records">
      Total Records: {{ total }}
    </div>
    <div style="display:none" class="hot-btns">
      <v-switch
        style="margin-right: 15px;margin-top:0;"
        v-model="status_q"
        label="Recent"
        color="#30838e"
        dense
        value="recent"
        hide-details
      ></v-switch>
      <v-switch
        style="margin-right: 15px;margin-top:0;"
        v-model="status_q"
        dense
        label="Qualified"
        color="#ff9900"
        value="qualified"
        hide-details
      ></v-switch>
      <!-- <v-switch
        style="margin-right: 15px"
        v-model="status_q"
        label="Qualified"
        color="#00aa33"
        value="qualified"
        hide-details
      ></v-switch> -->
      <!-- <v-switch
        style="margin-right: 15px"
        v-model="status_q"
        label="Scheduling Intake"
        color="#8314dd"
        value="ready"
        hide-details
      ></v-switch> -->
      <v-switch
      dense
        style="margin-right: 15px;margin-top:0;"
        v-model="status_q"
        label="Reffered"
        color="#28b69e"
        value="refer"
        hide-details
      ></v-switch>
      <v-switch
      dense
        style="margin-right: 15px;margin-top:0;"
        v-model="status_q"
        label="Admitted"
        color="#ab4c84"
        value="admitted"
        hide-details
      ></v-switch>
      <v-switch
        style="margin-right: 15px; margin-top:0;"
        v-model="status_q"
        label="Discharged"
        dense
        color="#343536"
        value="discharged"
        hide-details
      ></v-switch>

      <v-switch
        style="margin-right: 15px;margin-top:0;"
        v-model="status_q"
        label="Archived"
        color="#676668"
        dense
        value="archive"
        hide-details
      ></v-switch>
      
    </div>
    <v-client-table
      :style="isLoaded ? `` :`opacity:.4; pointer-events:none`"
      :perPage="50"
      :data="clients"
      :columns="columnLabels"
      :options="options"
      @sorted="sortMe"
    >
    <span style="display:inline-block" slot="h__original_contact_date">
      Contact Date
    </span>
    <span style="display:inline-block" slot="h__admitDate">
      Admit Date
    </span>
    <span style="display:inline-block" slot="h__insurance_company">
      Insurance
    </span>
    <div
        slot="time"
        style="white-space:nowrap; max-width:150px;"
        slot-scope="{ row }"
      >
        {{ moment(row.time).format('MM/DD/YYYY')}}<br><span style="color:#aeaeae">({{ moment(row.time).fromNow() }})</span>
      </div>
      <div
        slot="original_contact_date"
        style="white-space:nowrap; max-width:150px;"
        slot-scope="{ row }"
      >
        <span v-if="row.original_contact_date">{{ moment(row.original_contact_date).format('MM/DD/YYYY')}}</span>
      </div>
      <div
        slot="admitDate"
        style="white-space:nowrap; max-width:150px;"
        slot-scope="{ row }"
      >
        {{ row.admitDate ? moment(row.admitDate).utc().format('MM/DD/YYYY') : ''}}
      </div>
      <div
        slot="source"
        style="white-space:nowrap; max-width:150px;"
        slot-scope="{ row }"
      >
        <div v-if="row.source && row.source.indexOf('Psychology Today') > -1" style="margin-left:auto"><img :src="psychtodaySource" style="width:100px;" alt=""></div>
        <div v-if="row.source.toLowerCase().indexOf('path') > -1" style="margin-left:auto"><img :src="rehabPathSource" style="width:100px;" alt=""></div>
        <div v-if="row.source.indexOf('Google A') > -1" style="margin-left:auto"><img :src="googleAdsSource" style="width:100px;" alt=""></div>
        <div v-if="row.source.indexOf('Google My') > -1" style="margin-left:auto"><img :src="gmbSource" style="width:100px;" alt=""></div>
        <div v-if="row.source.indexOf('Website') > -1" style="margin-left:auto"><img :src="websiteSource" style="width:100px;" alt=""></div>
        <div v-if="row.source.indexOf('Organic') > -1" style="margin-left:auto">Organic</div>
        <div v-if="row.source.indexOf('Direct') > -1" style="margin-left:auto">Direct</div>
      </div>

      <!-- <div
        slot="tag"
        slot-scope="{ row }"
        v-html="row.tag.replace(/_/g, ' ')"
      >
        
      </div> -->
      <div
        slot="status"
        style="max-width: 90px;"
        slot-scope="{ row }"
        class="status-tag"
        @click="createFilter(row.status)"
        :class="row.status.replace(/ /g, '-')"
      >
        {{ row.status }}
      </div>
      <div
        slot="account_number"
        style="max-width: 100px"
        slot-scope="{ row }"
        v-html="
          searchField == 'account_number'
            ? highlightTerm(row.account_number)
            : row.account_number
        "
      >
        {{ row.account_number }}
      </div>
      <div
        slot="phone"
        slot-scope="{ row }"
        style="white-space:nowrap"
        v-html="
          searchField == 'phone'
            ? highlightTerm(formatPhoneNumber(row.phone))
            : formatPhoneNumber(row.phone)
        "
      ></div>
      <div
        slot="assignedUser"
        style="max-width: 100px"
        slot-scope="{ row }"
        v-html="
          row.assignedUser && availableUsers.filter((item)=>item.value ==row.assignedUser)[0].text
        "
      ></div>
      <!-- <div
        slot="modified"
        
        slot-scope="{ row }"
        v-html="
          row.modified != '' ? `${row.modified[0].first_name} ${row.modified[0].last_name} ${moment(row.modified_at).fromNow()}`: ''
        "
      ></div> -->
      <div
        slot="insurance_company"
        slot-scope="{ row }"
        :class="row.insurance_company"
        class="insurance-tag"
        v-if="row.insurance_company != ''"
      >
      <span v-if="row.insurance_company != ''">{{ row.insurance_company && availableInsurances.filter((item)=>item.value ==row.insurance_company)[0].text != null ? availableInsurances.filter((item)=>item.value ==row.insurance_company)[0].text : row.insurance_company }}</span>
    </div>
      
      <a
        
        style="display: flex; position:relative; cursor: pointer; align-items: center"
        slot="name"
        slot-scope="{ row }"
        @click="activateLead(row)"
      >


            <div
              slot="activator"
              class="clickName"
              style="margin-left: 10px; text-transform: capitalize"
              
            >
            <div style="margin-right:7px;" v-if="row.goodLead" ><i style="color:#41c05c" class="fa fa-thumbs-up"></i></div>
            <div v-if="false"  class="p-image">
              <img :ref="`e-${row._id}`" :src="`/api/static/${row._id}/profile.jpg`" onerror="this.src='https://us.123rf.com/450wm/apoev/apoev1902/apoev190200141/125038134-person-gray-photo-placeholder-man-in-a-costume-on-gray-background.jpg?ver=6'" alt=""/>
            </div> 
            <div v-if="false" style="position:absolute;right:10px; top:50%; transform:translateY(-50%)" >
              <div v-if="row.source && row.source.indexOf('Psychology Today') > -1" style="margin-left:auto"><img :src="psychtodaySource" style="width:100px;" alt=""></div>
              <div v-if="row.source.toLowerCase().indexOf('path') > -1" style="margin-left:auto"><img :src="rehabPathSource" style="width:100px;" alt=""></div>
              <div v-if="row.source.indexOf('Google A') > -1" style="margin-left:auto"><img :src="googleAdsSource" style="width:100px;" alt=""></div>
              <div v-if="row.source.indexOf('Google My') > -1" style="margin-left:auto"><img :src="gmbSource" style="width:100px;" alt=""></div>
              <div v-if="row.source.indexOf('Website') > -1" style="margin-left:auto"><img :src="websiteSource" style="width:100px;" alt=""></div>
              <div v-if="row.source.indexOf('Organic') > -1" style="margin-left:auto">Organic</div>
              <div v-if="row.source.indexOf('Direct') > -1" style="margin-left:auto">Direct</div>
            </div> 
            
              <span class="name-tag" v-if="$mq == 'sm'" :class="`m-${row.status}`">{{row.status}}</span>
              <span style="white-space:nowrap;" v-if="row.name != null">{{row.name.toUpperCase() }}</span><span v-else>NO NAME</span>
              <span   class="name-tag normal" v-if="$mq == 'sm'">{{row.phone}}</span>
              <span  style="white-space:nowrap;" class="name-tag normal" v-if="$mq == 'sm'">{{row.email}}</span>
              </div>
      </a>

      
    </v-client-table>
    <div v-if="isLoaded" class="pages">
      <div v-if="page > 1" @click="previousPage" class="next-page">
        Previous Page
      </div>
      <div>Page: {{ page }}</div>
      <div @click="nextPage" class="next-page">Next Page</div>
    </div>
  </div>
  </div>

</template>
<script>
import axios from "axios";
import moment from "moment-timezone";
import {mapGetters} from 'vuex';
import EntryPopup from "../components/entryPopup.vue";
import FilterDrawer from '../components/filterDrawer.vue'
export default {
  name: "Leads",
  components:{
    EntryPopup,
    FilterDrawer
  },
  data() {
    return {
      direction:false,
      rehabPathSource:require('@/assets/RP-LOGO-1.png'),
      psychtodaySource:require('@/assets/psychtoday.png'),
      googleAdsSource:require('@/assets/Google-Ads-Logo-PNG.png'),
      gmbSource:require('@/assets/gmb.png'),
      websiteSource:require('@/assets/website.png'),
      loadedMeta: false,
      moment,
      clients: [],
      total: 0,
      isLoaded: false,
      pickerOpen: false,
      term: "",
      lastTerm: "",
      filterMenu: false,
      activeDataType: "number",
      filterMin: 0,
      filterMax: 0,
      filterValue: "",
      // filterStartDate: "",
      // filterEndDate: "",
      filterOperator: "between",
      filter: "",
      filterSort: "dec",
      filterError: false,
      filterErrorMessage: "",
      searchField: "name",
      columnSort: "time",
      notesLoading: false,
      activeNote: {},
      notesPane: "",
      status_q:
        typeof this.$route.query.status == "string"
          ? [this.$route.query.status]
          : this.$route.query.status,
      temp_status_q:['recent', 'qualified'],

      //fromdash
      leadOpen:false,
      activeLead:{},
      isDragging:false,
      timeout:null,
      clone:null,
      dw:null,
      dh:null,
      hoverBucket:null,
      hoverIdx:0,
      startIdx:0,
      startBucket:null,
      recent:[],
      refer:[],
      admitted:[],
      discharged:[],
      unqualified:[],
      closed:[],
      referred:[],
      lost:[],
      qualified:[],
      ready:[],
      archive:[],
      savingUser:false,
      savingStatus:false,
      savingInsurance:false,
      notesLoading:false,
      newNoteMessage:'',
      showAddNote:false,
      savingTag:false,
      activateNextPage:false,
      availableTags:[
                    {text:'AA/NA Meetings',value:'AA/NA Meetings'},
                    {text:'Adolescent',value:'Adolescent'},
                    {text:'Alumni',value:'Alumni'},
                    {text:'Bad Insurance',value:'Bad Insurance'},
                    {text:'Call Back Scheduled',value:'Call Back Scheduled'},
                    {text:'Call Dropped',value:'Call Dropped'},
                    {text:'Clinic Sub/Meth',value:'Clinic Sub/Meth'},
                    {text:'Dead Air',value:'Dead Air'},
                    {text:'DUI Classes',value:'DUI Classes'},
                    {text:'Hang Up',value:'Hang Up'},
                    {text:'Has Private Insurance',value:'Has Private Insurance'},
                    {text:'Incoherent',value:'Incoherent'},
                    {text:'Looking for Patient/Employee',value:'Looking for Patient/Employee'},
                    {text:'Local Only',value:'Local Only'},
                    {text:'Loved One',value:'Loved One'},
                    {text:'Medicaid/Medicare',value:'Medicaid/Medicare'},
                    {text:'Mental Health Only',value:'Mental Health Only'},
                    {text:'Misdial',value:'Misdial'},
                    {text:'No Answer',value:'No Answer'},
                    {text:'No Insurance',value:'No Insurance'},
                    {text:'No Insurance Info',value:'No Insurance Info'},
                    {text:'Non-Addiction',value:'Non-Addiction'},
                    {text:'Other',value:'Other'},
                    {text:'Other Facility',value:'Other Facility'},
                    {text:'Outpatient',value:'Outpatient'},
                    {text:'Possible Insurance',value:'Possible Insurance'},
                    {text:'Poissble Private Pay',value:'Poissble Private Pay'},
                    {text:'Refferred Out',value:'Refferred Out'},
                    {text:'Sober',value:'Sober'},
                    {text:'Solicitation',value:'Solicitation'},
                    {text:'Spanish',value:'Spanish'},
                    {text:'Test',value:'Test'},
                    {text:'VIOP',value:'VIOP'},
                    {text:'VOB',value:'Spanish'},
                    {text:'Wrong Number',value:'Wrong Number'}

            ],
      leadNotes:[],
      availableInsurances:[],
      drawerOpen:false
    };
  },
  async mounted() {
    await this.getInsurances();
    let q = this.$route.query;
    if(q.sort == null){
        this.$router.replace({
        query: { ...this.$route.query, filter: 'time', sort: 'dec' },
      });
      }
    if (q.term != null) {
      this.term = q.term;
    }
    // if(q.startDate != null && q.endDate != null){
    //   this.filters.push({
    //     name:'dateRange',
    //     operator:'between',
    //     type:'date',
    //     value:[q.startDate, q.endDate]
    //   })
    // }
    if (q.status == null) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          status:['recent', 'qualified']
        },
      });
      this.status_q = [
        'recent', 'qualified'
      ];
      this.temp_status_q = [
        'recent', 'qualified'
      ];
      
    } 
    
    this.getLeads();
    if (this.page == null) {
      this.$router.replace({ query: { ...this.$route.query, page: 1 } });
    }
    window.addEventListener('keydown', (e)=>{

      switch (e.keyCode) {
        case 37:
            //alert('left');
            if(this.activateNextPage) return;
            if(this.activeLead != null){
              this.direction = true;
              let index = this.clients.findIndex((item)=>item._id == this.activeLead._id);
              if(index <= this.clients.length && index > 0){
                this.activeLead = this.clients[index - 1]
              }
              
              // if(index == 0){
              //   this.activateNextPage = true;
              //   this.$router.push({query: {...this.$route.query, page: Number(this.$route.query.page) + 1}})
              //   window.location.reload();
              // }
            }
            break;
        case 39:
            //alert('right');
            if(this.activateNextPage) return;
            if(this.activeLead != null){
              this.direction = false;
              let index = this.clients.findIndex((item)=>item._id == this.activeLead._id);
              
              if(index < this.clients.length-1 && index >= 0){
                this.activeLead = this.clients[index + 1]
              }
              if(index == this.clients.length - 1){
                this.activateNextPage = true;
                this.$router.replace({query: {...this.$route.query, page: Number(this.$route.query.page) + 1}})
                window.location.reload();
              }
              
            }
            break;
    }
    })
    this.goSearch();
  },
  watch: {
    leadOpen(v){
      if(!v){
        this.closeLead();
      }
    },
    term(val) {
      this.$router.replace({ query: { ...this.$route.query, term: val } });
    },
    status_q(val) {
      this.temp_status_q = val;
      this.$router.replace({ query: { ...this.$route.query, status: val } });
    },
  },
  methods: {
    exportToCsv(){
      this.isLoaded = false;
      if(this.term != ''){
        this.temp_status_q = [...this.status_q];
        this.status_q = []
      }else{
        this.status_q = this.temp_status_q;
      }
      this.$nextTick(()=>{
      this.lastTerm = this.term;
      let post = {
        field: this.searchField,
        term: this.term,
        page: this.page,
        sortName: this.$route.query.filter,
        sort: this.$route.query.sort,
        filters: this.filters,
        type: "master",
      };
      axios.post("/api/leads/export", post).then(async (res) => {
        let a = window.document.createElement('a');
        a.href = `${res.data.url}`;
        //a.download = res.data.output_path;
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
        this.isLoaded = true;

      });
      })
    },
    clearFilters(){
      let queryObj = Object.assign({},this.$route.query);
      delete queryObj.status;
      delete queryObj.source;
      delete queryObj.startDate;
      delete queryObj.startTime;
      delete queryObj.startAdmitDate;
      delete queryObj.endAdmitDate;
      delete queryObj.endDate;
      delete queryObj.endTime;
      delete queryObj.insurance;
      delete queryObj.tag;
      delete queryObj.from;
      delete queryObj.goodLead;
      delete queryObj.assignedUser;
      this.$router.replace({query:queryObj})
      this.goSearch();
    },

    removeTag(tag){
      
      let statusArr = this.$route.query.status
      let query = Object.assign({}, this.$route.query);
      statusArr.splice(statusArr.indexOf(tag), 1)
      this.$router.replace({query:{...this.$route.query, status: []}})
      if(statusArr.length > 0){
        this.$router.replace({query:{...this.$route.query, status: statusArr}})
      }else{
         delete query.status;
         this.$router.replace({query})
      }
      
      this.goSearch();
    },
    createFilter(status){
      this.temp_status_q = [status];
      this.status_q = [status];
      this.$nextTick(()=>{
         this.goSearch();
      })
     
    },
    formatDate2 (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },

    getInsuranceLabel(item){
            let mapping =  [
            {text:'None', value:''},
            {text:'Aetna', value:'aetna'},
            {text:'Cigna', value:'cigna'},
            {text:'Medicaid', value:'medicaid'},
            {text:'UMR', value:'umr'},
            {text:'Humana', value:'humana'},
            {text:'Optum', value:'optum'},
            {text:'Beacon', value:'beacon'},
            {text:'United Health', value:'united'},
            {text:'BCBS', value:'bcbs'},
            {text:'HMSA', value:'hmsa'},
            {text:'HMAA', value:'hmaa'},
            {text:'UHA', value:'uha'},
            {text:'Private Pay', value:'private_pay'}
            ]
            return mapping[mapping.findIndex((item2)=>item2.value == item)] && mapping[mapping.findIndex((item3)=>item3.value == item)].text;
    },
    async getInsurances(){
      try{
        let { data } = await axios.get('/api/insurance/all');
        this.availableInsurances = [{text:'None', value:''}, ...data.items.map((item)=>{
          return {
            value:item.name,
            text:this.getInsuranceLabel(item.name)
          }
        })];
      }catch(e){
        console.log(e);
      }
    },
    async changeAssignedUser(user){
        this.savingUser = true;
        let post = {
            assignedUser:user
        }
        try{
            let {data} =await axios.put('/api/leads/edit/' + this.activeLead._id, post);
            this.savingUser = false;
        }catch(e){
            this.savingUser = false;
        }
        this.getLeads();
    }, 
    async updateFilter(item){
      
      this.isLoaded = false;
      let queryObj = Object.assign({},this.$route.query);
      delete queryObj.status;
      delete queryObj.source;
      delete queryObj.startDate;
      delete queryObj.endDate;
      delete queryObj.startTime;
      delete queryObj.endTime;
      delete queryObj.insurance;
      delete queryObj.tag;
      delete queryObj.goodLead;
      delete queryObj.from;
      delete queryObj.assignedUser;
      queryObj.page = 1;

      this.$router.replace({query:queryObj})

      if(item.status){
       this.$router.replace({query:{...queryObj, status: item.status}})
       queryObj = Object.assign({},this.$route.query);
      }
      if(item.source){
       this.$router.replace({query:{...queryObj, source: item.source}})
       queryObj = Object.assign({},this.$route.query);
      }
      if(item.insurance){
       this.$router.replace({query:{...queryObj, insurance: item.insurance}})
       queryObj = Object.assign({},this.$route.query);
      }
      if(item.assignedUser){
       this.$router.replace({query:{...queryObj, assignedUser: item.assignedUser}})
       queryObj = Object.assign({},this.$route.query);
      }
      if(item.tag){
       this.$router.replace({query:{...queryObj, tag: item.tag}})
       queryObj = Object.assign({},this.$route.query);
      }
      if(item.goodLead){
       this.$router.replace({query:{...queryObj, goodLead: item.goodLead}})
       queryObj = Object.assign({},this.$route.query);
      }
      if(item.from){
       this.$router.replace({query:{...queryObj, from: item.from}})
       queryObj = Object.assign({},this.$route.query);
      }
      if(item.dateRange && item.dateRange.startDate != null && item.dateRange.endDate != null){
        let dateFilter = this.filters.filter((item)=>{
          return item.name == 'dateRange'
        })
        this.$router.replace({query:{...this.$route.query, ...item.dateRange}})
        queryObj = Object.assign({},this.$route.query);
      }else{
        let dateFilter = this.filters.filter((item)=>item.name == 'dateRange');
        if(dateFilter.length > 0){
          //this.filters.splice(this.filters.findIndex((item)=>item.name == 'dateRange'), 1);
        }
      }

      if(item.admitDateRange && item.admitDateRange.startAdmitDate != null && item.admitDateRange.endAdmitDate != null){ // admit date filter
        let dateFilter = this.filters.filter((item)=>{
          return item.name == 'admitDateRange'
        })
        this.$router.replace({query:{...this.$route.query, ...item.admitDateRange}})
        queryObj = Object.assign({},this.$route.query);
      }else{
        let dateFilter = this.filters.filter((item)=>item.name == 'admitDateRange');
        if(dateFilter.length > 0){
          //this.filters.splice(this.filters.findIndex((item)=>item.name == 'dateRange'), 1);
        }
      }

      if(item.timeRange && item.timeRange.startTime != null && item.timeRange.endTime != null){
        let timeFilter = this.filters.filter((item)=>{
          return item.name == 'timeRange'
        })
        this.$router.replace({query:{...this.$route.query, ...item.timeRange}})
        queryObj = Object.assign({},this.$route.query);
      }else{
        let timeFilter = this.filters.filter((item)=>item.name == 'timeRange');
        if(timeFilter.length > 0){
          //this.filters.splice(this.filters.findIndex((item)=>item.name == 'dateRange'), 1);
        }
      }

      this.goSearch();
      // this.savingEntry = true;
      //   let post = {
      //       status
      //   }
      //   try{
      //       let {data} = await axios.put('/api/leads/edit/' + this.activeLead._id, post);
      //       this.savingEntry = false;
      //   }catch(e){
      //       this.savingEntry = false;
      //   }
      //     this.getLeads();
    },
    async changeItem(item){
      this.savingEntry = true;
        let post = {
            status
        }
        try{
            let {data} = await axios.put('/api/leads/edit/' + this.activeLead._id, post);
            this.savingEntry = false;
        }catch(e){
            this.savingEntry = false;
        }
          this.getLeads();
    },
    async changeStatus(status){
        this.savingStatus = true;
        let post = {
            status
        }
        try{
            let {data} =await axios.put('/api/leads/edit/' + this.activeLead._id, post);
            this.savingStatus = false;
        }catch(e){
            this.savingStatus = false;
        }
          this.getLeads();
    }, 
    async changeInsurance(insurance){
          this.savingInsurance = true;
          let post = {
              insurance_company:insurance
          }
          try{
              let {data} =await axios.put('/api/leads/edit/' + this.activeLead._id, post);
              this.savingInsurance = false;
          }catch(e){
              this.savingInsurance = false;
          }
              this.getLeads();
    }, 
    async changeTag(tag){

        this.savingTag = true;
        let post = {
            tag
        }
        try{
            let {data} =await axios.put('/api/leads/edit/' + this.activeLead._id, post);
            this.savingTag = false;
        }catch(e){
            this.savingTag = false;
        }
        this.getLeads();

    },
    async addNote(){
        this.notesLoading = true;
        let note = {
            lead_id:this.activeLead._id,
            date:new Date(),
            message:this.newNoteMessage,
            user_name:this.user.first_name + ' ' + this.user.last_name,
            user_id:this.user.id
        }
        await axios.post(`/api/leads/add_note/${this.activeLead._id}`, note);
        this.showAddNote = false;
        this.newNoteMessage = '';
        this.getNotes(this.activeLead._id);
    },
    async deleteNote(id){
        this.notesLoading = true;
        await axios.delete(`/api/leads/delete_note/${id}`);
        this.getNotes(this.activeLead._id);
    },
    async getNotes(id){
            this.notesLoading = true;
            
            let {data} = await axios.get('/api/leads/get_notes/' + id);
            this.leadNotes = data.notes.reverse();
            this.notesLoading = false;
        },
    closeLead(e){
            this.getInsurances();
            this.activeLead = {};
            this.leadNotes = [];
            this.isLoaded = false;
            this.getLeads();
            this.leadOpen = false;
          
        },
    async activateLead(lead){
            this.getNotes(lead._id);
            this.activeLead = lead;
            this.leadOpen = true;
        },
    async getImage(ref){
      let errorHandler = (msg,file_loc,line_num) => {
        this.$refs[ref].src = require(`@/assets/male.webp`);
      }
      this.$refs[ref].onerror = errorHandler();

      
      // let id = ref.split('e-')[1];
      // console.log(id);
      // try{
      //   let data = await axios.get(`/api/static/${id}/profile.jpg`);
      //   console.log(this.$refs[ref])
      //    this.$refs[ref].src = `/api/static/${id}/profile.jpg`;
      // }catch(e){
      //   this.$refs[ref].src = require(`@/assets/male.webp`); 
      // }
      
     
    },
    clearFilter() {
      this.filter = "";
      this.activeDataType = "";
      this.filterMin = "";
      this.filterMax = "";
      this.filterValue = "";
      this.filterStartDate = "";
      this.filterOperator = "";

      var post = {
        term: this.term,
        field: this.searchField,
        filters: [
          {
            name: this.filter,
            type: this.activeDataType,
            min: this.filterMin,
            max: this.filterMax,
            value: this.filterValue,
            startDate: this.filterStartDate,
            operator: this.filterOperator,
          },
        ],
        sort: this.filterSort,
        sortName: this.filter,
        type: "all",
        page: this.page,
      };
      axios.post("/api/leads/search", post).then(async (res) => {
        let clients = res.data.items;
        for(let i in clients){
          if(clients[i].status == null){
            clients[i].status = 'none'
          }
          if(clients[i].tag == null){
            clients[i].tag = '2'
          }
        }
        
        if(this.filterSort == 'dec'){
          this.clients = clients;
        }else{
          this.clients = clients.reverse();
        }
        this.clients = this.clients && this.clients.map((item)=>{
          return {
            ...item,
            "status":item.status || '',
            "insurance_company":item.insurance_company || '',
            "name":item.name || 'No Name',
            "modified":item.modified.length > 0 ? item.modified : '',
            "modified_at":item.modified_at || '',
            "source":item.source || '',
            "time":item.time || '',
            "phone":item.phone || '',
            "assignedUser":item.assignedUser || '',
            "tag":item.tag || '',
            "goodLead": item.goodLead || ''
          }
        })
        this.isLoaded = true;

        this.total = res.data.total;
      });
    },
    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    },
    sortMe(eve) {
      let f = "asc";
      if (this.$route.query.sort == "asc") {
        f = "dec";
      } else {
        f = "asc";
      }
      this.columnSort = eve.column;
      this.$router.replace({
        query: { ...this.$route.query, filter: eve.column, sort: f },
      });

      this.goSearch();
    },
    highlightTerm(t) {
      if (this.lastTerm == "") {
        return t;
      }
      if (t == null) {
        return t;
      }
      let nterm = this.lastTerm.replace(/[()-]/g, "");
      nterm = nterm.replace(/\s+/g, "");
      let text = nterm;

      // t = t.replace(/[.*+?^${}()|[\]\\\s-]/g, '');

      t = t.replace(
        new RegExp(text, "gi"),
        (str) => `<span class='highlight'>${str}</span>`
      );

      return this.formatPhone(t);
      // var innerHTML = t.toString();
      // var index = innerHTML.indexOf(text);
      // if (index >= 0) {
      // innerHTML = innerHTML.substring(0,index) + "<span class='highlight'>" + innerHTML.substring(index,index+text.length) + "</span>" + innerHTML.substring(index + text.length);

      // }
      // return innerHTML;
    },
    async submitFilter() {
      let query = Object.assign({}, this.$route.query);
      this.$router.replace({ query: { ...query, page: 1 } });
      if (this.activeDataType == "number") {
        if (this.filterOperator == "between") {
          if (this.filterMin == 0 || this.filterMax == 0) {
            this.filterError = true;
            this.filterErrorMessage = "Please Fix the issues";
            return;
          }
        } else {
          if (this.filterValue == "" || this.filterOperator == "") {
            this.filterError = true;
            this.filterErrorMessage = "Please Fix the issues";
            return;
          }
        }
      }
      if (this.activeDataType == "string") {
        if (this.filterValue == "" || this.filterOperator == "") {
          this.filterError = true;
          this.filterErrorMessage = "Please Fix the issues";
          return;
        }
      }
      if (this.activeDataType == "date") {
        if (this.filterStartDate == "" || this.filterOperator == "") {
          this.filterError = true;
          this.filterMessage = "Please Fix the issues";
          return;
        }
      }
      // this.filters = this.filters.filter((item)=>{return item.name == "status";});
      // this.filters.push( {
      //       name: this.filter,
      //       type: this.activeDataType,
      //       min: this.filterMin,
      //       max: this.filterMax,
      //       value: this.filterValue,
      //       startDate: this.filterStartDate,
      //       operator: this.filterOperator,
      //     })
      var post = {
        term: this.term,
        field: this.searchField,
        filters: this.filters,
        sortName: this.$route.query.filter || "name",
        sort: this.$route.query.sort || "asc",
        type: "master",
        page: this.page,
      };
      await axios.post("/api/leads/search", post).then(async (res) => {
        let clients = res.data.items;
        for(let i in clients){
          if(clients[i].status == null){
            clients[i].status = 'none'
          }
        }

        if(this.filterSort == 'dec'){
          this.clients = clients;
        }else{
          this.clients = clients.reverse();
        }
        if(clients.length > 0){
        this.clients = this.clients.map((item)=>{
          return {
            ...item,
            "status":item.status || '',
            "insurance_company":item.insurance_company || '',
            "name":item.name || 'No Name',
            "source":item.source || '',
            "modified":item.modified.length > 0 ? item.modified : '',
            "modified_at":item.modified_at || '',
            "time":item.time || '',
            "phone":item.phone || '',
            "assignedUser":item.assignedUser || '',
            "tag":item.tag || '',
            "goodLead": item.goodLead || ''
          }
        })
       

        
       
        
      }
        this.isLoaded = true;

        this.total = res.data.total;
      });
    },
    getDataType(filter, i) {
      var arr = this.columns.filter((item, i) => {
        return item.value == filter;
      });
      if (arr[0].type == "string") {
        this.filterOperator = "contains";
      }
      if (arr[0].type == "number") {
        this.filterOperator = "between";
      }
      if (arr[0].type == "date") {
        this.filterOperator = "before";
      }
      if (arr[0].type == "bool") {
        this.filterOperator = "equals";
      }
      this.activeDataType = arr[0].type;
    },
    nextPage() {
      this.isLoaded = false;
      this.$router.replace({
        query: {
          ...this.$route.query,
          page: Number(this.page) + 1,
        },
      });
      this.goSearch();
    },
    previousPage() {
      this.isLoaded = false;
      this.$router.replace({
        query: { ...this.$route.query, page: Number(this.page) - 1 },
      });
      this.goSearch();
    },
    goSearch() {
       this.isLoaded = false;
      if(this.term != ''){
        this.temp_status_q = [...this.status_q];
        this.status_q = []
      }else{
        this.status_q = this.temp_status_q;
      }
      this.$nextTick(()=>{
      this.lastTerm = this.term;
      let post = {
        field: this.searchField,
        term: this.term,
        page: this.page,
        sortName: this.$route.query.filter,
        sort: this.$route.query.sort,
        filters: this.filters,
        type: "master",
      };
      axios.post("/api/leads/search", post).then(async (res) => {
        let clients = res.data.items;
        for(let i in clients){
          if(clients[i].status == null){
            clients[i].status = 'none'
          }
          if(clients[i].tag == null){
            clients[i].tag = ''
          }
        }
        if(this.filterSort == 'dec'){
          this.clients = clients;
        }else{
          this.clients = clients.reverse();
        }
        
        this.clients = clients && this.clients.map((item)=>{
          return {
            ...item,
            "status":item.status || '',
            "insurance_company":item.insurance_company || '',
            "name":item.name || 'No Name',
            "modified":item.modified.length > 0 ? item.modified : '',
            "modified_at":item.modified_at || '',
            "source":item.source || '',
            "time":item.time || '',
            "phone":item.phone || '',
            "assignedUser":item.assignedUser || '',
            "tag":item.tag || '',
            "goodLead": item.goodLead || '' 
          }
        })
       

        this.isLoaded = true;
        

        this.total = res.data.total;
        
      });
      })
    },
    formatPhone(phoneNumberString) {
      if (phoneNumberString.length == 7) {
        var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return "(" + match[1] + ") " + match[2] + "-" + match[3];
        }
      } else {
        return phoneNumberString;
      }
    },
    formatDate(date2) {
      if (new Date(date2) < new Date("1970-12-30")) {
        return "";
      } else {
        return moment(date2).format("MM/DD/YYYY");
      }
    },
    getLeads() {
      let post = {
        field: this.searchField,
        term: this.term,
        page: this.page,
        sortName: this.$route.query.filter || "name",
        sort: this.$route.query.sort || "asc",
        filters: this.filters,
        type: "master",
      };
      axios.post("/api/leads/search", post).then(async (res) => {
        let clients = res.data.items;
        for(let i in clients){
          
          if(clients[i].status == null){
            clients[i].status = 'none'
          }
        }
        if(this.filterSort == 'dec'){
          this.clients = clients;
        }else{
          this.clients = clients.reverse();
        }

        this.clients = this.clients.map((item)=>{
          return {
            ...item,
            "status":item.status || '',
            "insurance_company": item.insurance_company || '',
            "name":item.name || 'No Name',
            "source":item.source || '',
            "modified":item.modified.length > 0 ? item.modified : '',
            "modified_at":item.modified_at || '',
            "time":item.time || '',
            "phone":item.phone || '',
            "assignedUser":item.assignedUser || '',
            "tag":item.tag || '',
            "goodLead": item.goodLead || ''
          }
        })
        
        
        this.isLoaded = true;
        setTimeout(()=>{
          for(let i in clients){
         // this.getImage('e-'+clients[i]._id);
          
        }
        },100)
        

        this.total = res.data.total;
      });
    },
  },
  computed: {
    ...mapGetters({
      users:'AUTH_USERS',
      user:'AUTH_USER',
    }),
    filters(){
      let filters = [];
      let statuses = this.$route.query.status || [];
      let sources = this.$route.query.source || [];
      let insurances = this.$route.query.insurance || [];
      let assignedUsers = this.$route.query.assignedUser || [];
      let tags = this.$route.query.tag || [];
      let froms = this.$route.query.from || [];
      let q = this.$route.query;
      if(q.startDate != null && q.endDate != null){
        filters.push({
          name:'dateRange',
          operator:'between',
          type:'date',
          value:[q.startDate, q.endDate]
        })
      }
      if(q.startAdmitDate != null && q.endAdmitDate != null){
        filters.push({
          name:'admitDateRange',
          operator:'between',
          type:'admitDate',
          value:[q.startAdmitDate, q.endAdmitDate]
        })
      }
      if(q.startTime != null && q.endTime != null){
        filters.push({
          name:'timeRange',
          operator:'between',
          type:'time',
          value:[q.startTime, q.endTime]
        })
      }
      if(statuses.length > 0){
        filters.push({
        name:'status',
        type:'string',
        value:statuses,
        operator:'array'
        })
      }
      if(sources.length > 0){
        filters.push({
        name:'source',
        type:'string',
        value: sources,
        operator:'array'
        })
      }
      if(insurances.length > 0){
        filters.push({
        name:'insurance_company',
        type:'string',
        value: insurances,
        operator:'array'
        })
      }
      if(assignedUsers.length > 0){
        filters.push({
        name:'assignedUser',
        type:'string',
        value: assignedUsers,
        operator:'array'
        })
      }
      if(tags.length > 0){
        filters.push({
        name:'tag',
        type:'string',
        value: tags,
        operator:'array'
        })
      }
      if(froms.length > 0){
        filters.push({
        name:'from',
        type:'string',
        value: froms,
        operator:'array'
        })
      }
      return filters;
    },
    role(){
      return this.user.role;
    },
    availableStatuses(){
            return [
            {text:'Recent', value:'recent'},
                {text:'VOB', value:'vob'},
                {text:'Qualified', value:'qualified'},
                {text:'Unqualified', value:'unqualified'},
                {text:'Lost', value:'lost'},
                {text:'Closed', value:'closed'},
                // {text:'Referred', value:'referred'},
                {text:'Admitted', value:'admitted'},
                {text:'Archived', value:'archive'},
                {text:'Waitlist', value:'waitlist'},
                {text:'Discharged', value:'discharged'}
            ]
        },
    availableUsers(){
      return this.users.map((item)=>{
          return {text:`${item.first_name} ${item.last_name}`, value:item._id};
      })
    },
    isDisputeFilter(){
      let f = this.filters.filter((item)=>item.name.indexOf('dispute') > -1);
      if(f.length > 0){
        return true;
      }else{
        return false;
      }
    },
    searchColumns() {
      return [
        { label: "Account Number", value: "account_number" },
        { label: "Name", value: "name" },
        { label: "Address", value: "address" },
        { label: "Phone Numbers", value: "phone" },
      ];
    },
    page() {
      return this.$route.query.page;
    },

    columns() {
      return [
        


      ];
    },
    columnLabels() {
      // return this.columns.map((item, i) => {
      //   return item.value;
      // });
      if(this.$mq == 'sm'){
        var columns = [
           
            "name",
            //"phone",
            // "from",
            // "provider",
            
          ];
      }else{
        var columns = [
        "status",
        "insurance_company",
        "name",
        "source",
        "phone",
        
        "time",
        "original_contact_date",
        "admitDate",
        
        // "assignedUser",
        
        
      ];

      }

      
      if (this.filter != "") {
        columns.push(this.filter.toLowerCase().replace(" ", "_"));
      }
      // if(this.role == 'admin'){
      //   columns.push('modified');
      // }
      if (this.isDisputeFilter) {
        columns.push('disputed_on', 'contacted_on');
      }
      return columns;
    },
    options() {
      return {
        
        perPage: 50,
        sortIcon: {
          base: "fal",
          up: "fa-caret-down",
          down: "fa-caret-up",
          is: "",
        },
        pagination:{
            show:false,
        },
        filterable: false,
        sortingAlgorithm: (data, column) => {
          return data;
        },
      };
    },
  },
};
</script>
<style lang="scss">
.filter-tags{
  display:flex;
  align-items: center;
  gap:10px;
  margin-bottom:10px;
  .filter-tag{
    i{

      margin-right:6px;
      transition:all .3s ease;
      height:18px;
      width:18px;
      display:flex;
      align-items:center;
      justify-content: center;
      border-radius: 14px;
      cursor:pointer;
      &:hover{
        background:#00000030;
      }
    }
    border-radius: 50px;
    height:25px;
    display:flex;
    align-items: center;
    padding:0px 15px 0px 5px;
    background:#eaeaea;
    font-size:10pt;
    color:#fff;
    &.recent{
      background:#30838e;
    }
    &.qualified{
    background:#ff9900;
  }
  &.vob{
      background:#aa9821;
  }
  &.ready{
    background:#8314dd;
  }
  &.referred{
    background:#28b69e;
  }
  &.unqualified{
    background:#808080;
  }
  &.lost{
    background:#808080;
  }
  &.closed{
    background: #205cb5;
  }
  &.admitted{
    background:#ab4c84;
  }
  &.archived{
    background:#676668;
  }
  }
}
.table-bordered{
  thead{
    tr{
      
      th{
      &:nth-child(1){
        width:2.0909%;
        max-width: 2.0909%;
      }
      &:nth-child(2){
        width:2.0909%;
        max-width: 2.0909%;
      }
      &:nth-child(3){
        width:12.0909%;
        max-width: 12.0909%;
      }
      &:nth-child(4){
        width:2.0909%;
        max-width: 2.0909%;
      }
      &:nth-child(5){
        width:9.0909%;
        max-width: 9.0909%;
      }
      &:nth-child(6){
        width:9.0909%;
        max-width: 9.0909%;
      }
      &:nth-child(7){
        width:9.0909%;
        max-width: 9.0909%;
      }
      &:nth-child(8){
        width:9.0909%;
        max-width: 9.0909%;
      }
      &:nth-child(9){
        width:9.0909%;
        max-width: 9.0909%;
      }
      &:nth-child(10){
        width:9.0909%;
        max-width: 9.0909%;
      }
      &:nth-child(11){
        width:9.0909%;
        max-width: 9.0909%;
      }

    }
    }
    
  }
  tbody{
    tr{
      td{
        max-width:7vw;
        width:7vw;
        min-width: 7vw;
        white-space: wrap;
        div{
          white-space:wrap !important;
        }
        span{
          text-align:left;
          white-space:wrap !important;
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import "@/assets/scss.scss";
.leads-stage{
  padding-left:250px !important;
  @include media-query($medium-down) {
    padding-left:0px !important;
  }
}
.insurance-tag{
  color:#fff;
  background:#808080;
  min-height:24px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  border-radius: 100px;
  white-space:nowrap;
  span{
    text-align:center !important;
   // padding:5px 5px;
  }
  font-size: 9pt;
  text-transform: capitalize;
}
.aetna{
  background:#7400aa !important;
}
.kaiser{
  background:#00aa25 !important;
}
.tricare{
  background:#5e7377 !important;
}
.united{
  background:#5e6893 !important;
}

.cigna{
  background:#408cb9 !important;
}

.beacon{
  background:#266c94 !important;
}

.humana{
  background:#68b754 !important;
}

.optum{
  background:#d4581a !important;
}

.bcbs{
  background:#3080e8 !important;
}
.private_pay{
  background:#878787 !important;
}
.hmaa{
  background:#65c1b9 !important
}
.hmsa{
  background:#5175bb !important
}
.uha{
  background:#65a49f !important
}

.add-note-icon{
    padding:10px;
    transition:all .3s ease;
    cursor: pointer;
    &:hover{
        transform:scale(1.3, 1.3);
    }
}
.note-item{
        padding:15px 8px;
        width:100%;
        border:solid 1px #eaeaea;
        border-radius: 4px;
        margin-bottom:15px;
        text-align: left;
        .note-title{
            display:flex;
            margin-bottom:10px;
            font-size:10pt;
            text-transform: uppercase;
            letter-spacing: 1px;
            color:#808080;
            display: flex;
            align-items: center;
            .fa-trash{
                margin-left:10px;
                transition:all .3s ease;
                cursor: pointer;
                &:hover{
                    color:#ff0000;
                }
            }
        }
        .note-body{
            font-size: 12pt;
            white-space:pre-line;
            
        }
    }
.drag-handle-container{
    height:35px; 
    width:35px; 
    color:#aeaeae; 
    transform:translateX(-10px);
    display:flex; 
    align-items:center; 
    justify-content:center;
    @include media-query($medium-down) {
       display:none;
    }
}
.active-lead-notes{
    .active-lead-notes-section{
        margin-top:15px;
        display:flex;
        align-items: center;
        flex-direction: column;
        min-height:100px;
        max-height:250px;
        
        overflow: auto;
        .no-notes{
            display:flex;
            align-items: center;
            justify-content: center;
            background:#f8fafb;
            border:solid 1px #eaeaea;
            color:#808080;
            font-size:12pt;
            text-transform: uppercase;
            letter-spacing: 2px;
            height:100px;
            width:100%;
            
        }
    }
    padding:15px;
}
.chip{
    font-size: 9pt;
    font-weight: lighter;
    color: #808080;
    display: inline-block;
    background: #ffffff;
    margin-top: 5px;
    border: solid 1px #eaeaea;
    border-radius: 100px;
    padding: 2px 10px;
    i{
        margin-right:5px;
        
    }

}
.c-card{
    position:relative;
    overflow:hidden;
    box-shadow: $box-shadow;
    border-radius: 5px;
    margin-bottom:15px;
    &.small{
        max-height:100px;
    }
    @include media-query($medium-down) {
        padding:0 !important;
        box-shadow: none !important;
        min-height: 500px;
    }
    .c-title{
        cursor:pointer;
        width:100%; 
        
        i{
            margin-right: 10px;
            transform-origin: center center;
            
            height:25px;
            width:25px;
            display:flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition:all .3s ease;
        }
        padding:25px;
        font-size:15pt;
        text-transform: uppercase;
        color:var(--app-color);
        letter-spacing: 2px;
        font-weight: bold;
        text-align: left;
    }
    .c-card-inner{
        display:flex;
        padding:15px;
        max-height: calc(100% - 130px);
        overflow: auto;
        overflow-x:hidden;
        margin-top:15px;
        box-sizing:border-box;
        @include media-query($medium-down) {
            padding:10px;
            background:#f8fafb;
            border:solid 1px #eaeaea;
        }
        
    }
}

.active-lead{
    
    .active-lead-title{
        font-size:15pt;
        text-transform: uppercase;
        letter-spacing: 2px;
        color:#808080;
         @include media-query($medium-down) {
            font-size:10pt;
        }
    }
    .active-lead-text{

        .flex{
            flex-wrap: wrap !important;
        }
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap:15px;
        padding:15px;
        text-align:left;
        @include media-query($medium-down) {
            grid-template-columns: 1fr 1fr;
        }
        .active-lead-item{
            width:100%;
            

            .active-lead-item-title{
                font-size:9pt;
                font-weight: normal;
                color:#000;
                border:solid 1px #eaeaea;
                background:#eaeaea;
                padding:5px 8px;
                font-weight: bold;
                border-top-left-radius: 5px;
              border-top-right-radius: 5px;
               
            }   
            .active-lead-item-value{
              min-height:35px;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
              display:flex;
              align-items: center;
                margin-left:auto;
                border:solid 1px #eaeaea;
                padding:5px 8px;
                border-top:0;
                transition:all .3s ease;
                
                &:hover{
                  &.editable{
                    box-shadow:inset 0px 0px 0px 3px #3080e8;
                    cursor: pointer;
                  }
                  
                }
                
                &.editing{
                  transition: none;
                  box-shadow:none;
                }
                
            }
        }
    }
}
.d-inner{
    display:grid;
    width:100%;
    padding:25px;
    grid-gap:25px;

    grid-template-columns: 1fr 1fr 1fr;
    @include media-query($medium-down) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        padding:0px;
    }
}
.none{
    padding:15px;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size:10pt;
    color:#808080;
    height:50px;
    width:100%;
    
    text-transform: uppercase;
    letter-spacing: 2px;
}
.drag-container{
    width:100%;
    display:none;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    background:#eaeaea;
    color:#808080;
    font-size:15pt;
    text-transform: uppercase;
    opacity: .3;
    bottom:0;
    &.active{
        background:#e0e0e0;
        opacity: .6;
    }
    i{
        margin-right:10px;
    }
    transition:all .3s ease;
    letter-spacing: 2px;
    border:dashed 2px #aeaeae;
    display:flex;
    align-items: center;
    justify-content: center;
    
}
.dash-container{
    
    text-align: left;
    width:100%;
    display:flex;

    padding-top:70px;
    padding-left:265px;
    overflow: auto;
    @include media-query($medium-down) {
        padding:15px;
        padding-top:85px
    }
    h1{
        color:var(--app-color);
        text-transform: uppercase;
        font-size:12pt;
        letter-spacing: 7px;
    }
}
.n-tag{
    color:#fff;
    background:var(--app-color);
    border-radius: 100px;
    padding:2px 7px; 
    margin-right:10px;
    display:flex;
   
    font-size:7pt;
    text-transform: uppercase;
    letter-spacing: 1px;
    display:inline-block

}
.timeago{
    color:#808080;
    font-size:.8em;
    margin-left:10px;
    white-space:nowrap
}
.leads{
    display:flex;
    flex-direction: column;
    width:100%;
    @include media-query($medium-down) {
        padding-right:10px;
        max-height:300px;
    }
   
}
 .lead-item{

        &.dragging{
            position:fixed;
            z-index:1000;
            cursor:grabbing !important;
            i{
                cursor:grabbing;
            }
            
        }
        &:hover{
            box-shadow:0px 6px 10px rgba(0,0,0,0.2);
            transform:scale(1.04,1.04);
        }
        
        .lead-item-inner{
            flex:1;
            display:flex;
            align-items: center;
        }
        cursor: pointer;
        transition:box-shadow, transform .3s ease;
        
        display:flex;
        align-items: center;
        background: #fff;
        box-shadow: $box-shadow;
        padding:15px;
        margin-bottom:10px;
        width:100%;
        flex-basis: 100%;
        flex:1;
        font-weight: bold;
        color:$blue;
        
        @include media-query($medium-down) {
            border:solid 1px #eaeaea;
            box-shadow: none;
        }
    }
    .side-drawer{
        height:calc(100vh - 120px);
        max-width:0px;
        transition:all .3s ease;
        overflow:hidden;
        padding-top:25px;
        height:100%;
        transform-origin: 0% 0%;
        width:100%;
        position:relative;
        display:flex;
        flex-direction: column;
        max-height:calc(100vh - 220px);
        &.active{
            max-width:350px;
        }
    }
.archive-panel{

    
    
    width:100%;
    overflow:hidden;
    
    height:calc(100vh - 220px);
    transition:all .3s ease;
    position:relative;
    // flex:1;

    .c-card{
        flex:1;
        height:100%;
        min-width:350px;
    }

}
.show-archived{
    background:#eaeaea;
    color:#808080;
    border-radius: 100px;
    cursor: pointer;
    height:35px;
    width:35px;
    position:absolute;
    top:80px;
    right:15px;
    display:flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    box-shadow:0px 5px 10px rgba(0,0,0,0.2);
    transition: all .3s ease;
   &:hover{
    background:#aeaeae;
    color:#000;

   }
}
</style>
<style lang="scss">
@import "@/assets/scss.scss";
.name-tag{
  font-size:8pt;
  text-transform: uppercase;
  letter-spacing: 2px;
  &.normal{
    letter-spacing: 0px;
    color:#808080;
    text-transform:none;
  }
}
.pages{
  display: flex; 
  align-items: center;
  @include media-query($medium-down) {
        padding:0px 15px;
    }
}
.total-records{
  display: flex; 
  align-items: center; 
  margin-bottom:15px;
   @include media-query($medium-down) {
        padding:0px 15px;
    }

}
.search-container{
  display: flex; 
  align-items: center;
  @include media-query($medium-down) {
        padding:15px;
        flex-direction: column;
        align-items: flex-start;
        width:100%;
    }
}
.v-input--selection-controls .v-input__slot>.v-label, .v-input--selection-controls .v-radio>.v-label{
margin-top: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: bold;
    margin-right: 15px;
}
    
.p-image{
  border-radius:100px; 
  height:50px; 
  width:50px;
  overflow:hidden; 
  margin-right:10px;
  @include media-query($medium-down) {
        display:none;
    }
  img{
    object-fit: cover;
    height:100%;
    width:100%;
  }
}
.form-inline{
    display:none !important;
}
.form-control {
  border-radius: 0px !important;
}
.form-control:focus {
  border-color: #24d7a0 !important;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__search-field label {
  margin-right: 10px;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff !important;
}
.table-striped tbody tr:nth-of-type(even) {
  background-color: #fff !important;
}
.table-hover tbody tr:hover {
  background-color: #f8fafb !important;
}
.VueTables__search {
  display: none !important;
}
.table thead th {
  background: #fff;
  border: none !important;
  height:50px !important;
  overflow:hidden !important;
  vertical-align: top !important;
  color: #000;
}
.table tbody tr td {
  vertical-align: middle;
}
.table{
  border-radius: 10px;
  @include media-query($medium-down) {
        border-radius: 0;
    }
}
.inner-stage{
   
    min-height:100vh;
    flex:1;
    padding:15px;
    padding-top:85px;
    padding-left:265px;
    padding-bottom:100px;

    background:#f8fafb;
    @include media-query($medium-down) {
        padding:0;
        padding-top:85px;
        padding-bottom:100px;
        overflow-x:hidden;
    }
}
.VueTables__sort-icon {
  margin-left: 10px;
}
.clickName {
  color:var(--app-color);
  display:flex;
  font-weight: bold;
  align-items:center;
  @include media-query($medium-down) {
        flex-direction: column;
        align-items:flex-start;
    }
}
.clickName:hover {
  color:var(--app-color);
  text-decoration: underline;
}
.wrapper {
  margin: 0px;
  padding: 0;
  margin-bottom: 0;
  background: #f8fafb;
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top:55px;
}

.wrapper-cell {
  width: 100%;
  display: flex;
  margin-bottom: 0px;
  padding: 0px 55px;
 
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -768px 0;
  }
  100% {
    background-position: 768px 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -768px 0;
  }
  100% {
    background-position: 768px 0;
  }
}
.animated-background,
.image,
.text-line {
  -webkit-animation-duration: 2.25s;
  animation-duration: 2.25s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f6f6;
  background: linear-gradient(to right, #e9ecee 8%, #d9dee1 18%, #e9ecee 33%);
  background-size: 800px 104px;
  height: 146px;
  position: relative;
}

.image {
  min-height: 160px !important;
  min-width: 160px !important;
}

.text {
  margin-left: 0px;
  position: relative;
  width: 100%;
}

.text-line {
  height: 55px;
  width: 100%;
  margin-bottom: 20px;
}
.highlight {
  background-color: #f5f0d2;
  border-radius: 4px;
  padding: 0px 1px;
}
.fieldSelect {
  background: #f5f5f5;
  border-radius: 4px;
  margin-right: 10px;
  padding: 10px;
  height: 60px;
}
.VueTables__sortable {
  cursor: pointer !important;
}

.m-recent{
    background:var(--app-color);
}
.m-qualified{
  background:#ff9900;
}
.m-ready{
  background:#8314dd;
}
.m-referred{
  background:#28b69e;
}
.m-unqualified{
  background:#808080;
}
.m-lost{
  background:#808080;
}
.m-closed{
  background: #205cb5;
}
.m-admitted{
  background:#ab4c84;
}
.m-archived{
  background:#676668;
}

.status-tag {
  cursor: pointer;

  &.recent{
    background:var(--app-color);
    display:none;
  }
  
  &.qualified{
    background:#ff9900;
  }
  &.vob{
      background:#aa9821;
  }
  &.ready{
    background:#8314dd;
  }
  &.referred{
    background:#28b69e;
  }
  &.unqualified{
    background:#808080;
  }
  &.lost{
    background:#808080;
  }
  &.closed{
    background: #205cb5;
  }
  &.admitted{
    background:#ab4c84;
  }
  &.archived{
    background:#676668;
  }
  border-radius: 100px;
  background: #aed7de;
  color: #fff;
  height: 24px;
  font-size: 9pt;
  padding: 2px 5px;
  justify-content: center;
  display:flex; align-items: center;
  white-space: nowrap;
  text-transform: capitalize;
}
.Owed {
  background: #30838e;
}
.Payment-Plan {
  background: #ff9900;
}
.Paid-in-Full {
  background: #00aa33;
}
.Dispute {
  background: #00aa33;
}
.Declined {
  background: #ff3333;
}
.hot-btns {
  display: inline-flex;
   @include media-query($medium-down) {
        display:flex;
        padding-left:10px;
        flex-direction: column;
    }
}
.fdraw{
  left:250px !important;
  @include media-query($medium-down) {
    left:unset !important
  }
}
.v-dialog{
  box-shadow:none !important;
  overflow-x:hidden !important;
}
</style>