import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/login';
import Dash from '../views/dash';
import Leads from '../views/leads';
import Lead from '../views/lead';
import Users from '../views/users';
import User from '../views/user';
import Reminders from '../views/reminders';
import Forms from '../views/forms';
import Form from '../views/form';
import EditForm from '../views/editForm';
import Inventory from '../views/inventory';
import Forgot from '../views/forgot';
import ResetPassword from '../views/reset_password'
import BedChart from '../views/bedchart';
import EditInventory from '../views/editInventory';
import CreateForm from '../views/createForm';
import Splash from '../views/splashpage';
import Admin from '../views/admin';


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Login
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/splash',
    name: 'Splash',
    component: Splash
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dash
   
  },
  {
    path: '/reminders',
    name: 'Reminders',
    component: Reminders
   
  },
  {
    path: '/crm',
    name: 'CRM',
    component: Leads
   
  },
  {
    path: '/lead/:id',
    name: 'Lead',
    component: Lead
   
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
   
  },
  {
    path: '/users/user/:id',
    name: 'User',
    component: User
   
  },
  {
    path: '/forms',
    name: 'Forms',
    component: Forms
   
  },
  {
    path: '/forms/create',
    name: 'Create Form',
    component: CreateForm
   
  },
  {
    path: '/forms/create/:id',
    name: 'Edit Form',
    component: CreateForm
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: Forms
   
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: Forgot
   
  },
  {
    path: '/reset_password',
    name: 'Reset Password',
    component: ResetPassword
   
  },
  {
    path: '/forms/:id',
    name: 'Form',
    component: Form
  },
  
  {
    path: '/inventory/:id',
    name: 'Inventory',
    component: Inventory
  },
  {
    path: '/inventory/:id/new',
    name: 'Add Inventory',
    component: EditInventory
  },
  {
    path: '/forms/:id/new',
    name: 'Add Form',
    component: EditForm
  },
  {
    path: '/forms/:id/edit/:formId',
    name: 'Edit Form',
    component: EditForm
  },
  {
    path: '/bedchart',
    name: 'Bed Chart',
    component: BedChart
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
