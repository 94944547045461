<template>
  <div class="filter-drawer">
    <div class="filter-top">
      <v-btn @click="$emit('close')" small depressed><i class="fa fa-angle-left"></i></v-btn>
      <v-btn style="margin-left:auto" @click="applyFilter" small depressed :color="appColor" dark>Apply Filter</v-btn>
    </div>
    <div class="filter-body">
      <div class="filter-section">
        <div @click="openSection('date')" class="f-section-title">Contact Date Filter <i
            :class="{ 'open': openSections.indexOf('date') > -1 }" class="fal fa-chevron-up"></i></div>
        <v-expand-transition>
          <div v-if="openSections.indexOf('date') > -1" class="filter-tags2" style="height:100px">
            <v-menu>
              <template v-slot:activator="{ on }">
                <v-btn x-small v-on="on" depressed text>between</v-btn>
              </template>
              <v-list small>
                <v-list-item small link>
                  <v-list-item-title>Between</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>



            <div class="frow">
              <div>
                <v-menu v-model="filterStartDateMenu" :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="filterStartFormatted" style="padding-top:0 !important;" clearable label="From"
                      @blur="filterStartDate = parseDate(filterStartFormatted)" class="fixMe" outlined dense
                      :color="appColor" readonly v-bind="attrs" v-on="on"
                      @click:clear="updateFilter({ label: 'start', type: '', value: '' })"></v-text-field>
                  </template>
                  <v-date-picker :color="appColor" v-model="filterStartDate"
                    @change="$event => { filterStartDateMenu = false; updateFilter({ label: 'start', type: '', value: $event }) }"></v-date-picker>
                </v-menu>
              </div>
              <div>
                <v-menu v-model="filterEndDateMenu" :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="filterEndFormatted" style="padding-top:0 !important;" clearable label="To"
                      @blur="filterEndDate = parseDate(filterEndFormatted)" class="fixMe" outlined dense :color="appColor"
                      readonly v-bind="attrs" v-on="on"
                      @click:clear="updateFilter({ label: 'end', type: '', value: '' })"></v-text-field>
                  </template>
                  <v-date-picker :color="appColor" v-model="filterEndDate"
                    @change="$event => { filterEndDateMenu = false; updateFilter({ label: 'end', type: '', value: $event }) }"></v-date-picker>
                </v-menu>
              </div>
            </div>
          </div>
        </v-expand-transition>
      </div>
      <div class="filter-section">
        <div @click="openSection('admitDate')" class="f-section-title">Admit Date Filter <i
            :class="{ 'open': openSections.indexOf('admitDate') > -1 }" class="fal fa-chevron-up"></i></div>
        <v-expand-transition>
          <div v-if="openSections.indexOf('admitDate') > -1" class="filter-tags2" style="height:100px">
            <v-menu>
              <template v-slot:activator="{ on }">
                <v-btn x-small v-on="on" depressed text>between</v-btn>
              </template>
              <v-list small>
                <v-list-item small link>
                  <v-list-item-title>Between</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>



            <div class="frow">
              <div>
                <v-menu v-model="filterStartAdmitDateMenu" :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="filterStartAdmitFormatted" style="padding-top:0 !important;" clearable label="From"
                      @blur="filterStartAdmitDate = parseDate(filterStartAdmitFormatted)" class="fixMe" outlined dense
                      :color="appColor" readonly v-bind="attrs" v-on="on"
                      @click:clear="updateFilter({ label: 'start', type: '', value: '' })"></v-text-field>
                  </template>
                  <v-date-picker :color="appColor" v-model="filterStartAdmitDate"
                    @change="$event => { filterStartAdmitDateMenu = false; updateFilter({ label: 'start', type: '', value: $event }, 'admit') }"></v-date-picker>
                </v-menu>
              </div>
              <div>
                <v-menu v-model="filterEndAdmitDateMenu" :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="filterEndAdmitFormatted" style="padding-top:0 !important;" clearable label="To"
                      @blur="filterEndAdmitDate = parseDate(filterEndAdmitFormatted)" class="fixMe" outlined dense :color="appColor"
                      readonly v-bind="attrs" v-on="on"
                      @click:clear="updateFilter({ label: 'end', type: '', value: '' })"></v-text-field>
                  </template>
                  <v-date-picker :color="appColor" v-model="filterEndAdmitDate"
                    @change="$event => { filterEndAdmitDateMenu = false; updateFilter({ label: 'end', type: '', value: $event }, 'admit') }"></v-date-picker>
                </v-menu>
              </div>
            </div>
          </div>
        </v-expand-transition>
      </div>

      <div class="filter-section">
        <div @click="openSection('time')" class="f-section-title">Time Filter <i
            :class="{ 'open': openSections.indexOf('time') > -1 }" class="fal fa-chevron-up"></i></div>
        <v-expand-transition>
          <div v-if="openSections.indexOf('time') > -1" class="filter-tags2" style="height:100px">
            <v-menu>
              <template v-slot:activator="{ on }">
                <v-btn x-small v-on="on" depressed text>between</v-btn>
              </template>
              <v-list small>
                <v-list-item small link>
                  <v-list-item-title>Between</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>



            <div class="frow">
              <div>
                <v-menu v-model="filterStartTimeMenu" :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="filterStartTimeFormatted" style="padding-top:0 !important;" clearable label="From"
                      @blur="filterStartTime = parseDate(filterStartTimeFormatted)" class="fixMe" outlined dense
                      :color="appColor" readonly v-bind="attrs" v-on="on"
                      @click:clear="updateFilter({ label: 'start', type: '', value: '' })"></v-text-field>
                  </template>
                  <v-date-picker :color="appColor" v-model="filterStartTime"
                    @change="$event => { filterStartTimeMenu = false; updateFilter({ label: 'start', type: '', value: $event }, 'time') }"></v-date-picker>
                </v-menu>
              </div>
              <div>
                <v-menu v-model="filterEndTimeMenu" :close-on-content-click="false" max-width="290">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="filterEndTimeFormatted" style="padding-top:0 !important;" clearable label="To"
                      @blur="filterEndTime = parseDate(filterEndTimeFormatted)" class="fixMe" outlined dense :color="appColor"
                      readonly v-bind="attrs" v-on="on"
                      @click:clear="updateFilter({ label: 'end', type: '', value: '' })"></v-text-field>
                  </template>
                  <v-date-picker :color="appColor" v-model="filterEndTime"
                    @change="$event => { filterEndTimeMenu = false; updateFilter({ label: 'end', type: '', value: $event }, 'time') }"></v-date-picker>
                </v-menu>
              </div>
            </div>
          </div>
        </v-expand-transition>
      </div>



      <div class="filter-section">
        <div @click="openSection('status')" class="f-section-title">Status Filter <i
            :class="{ 'open': openSections.indexOf('status') > -1 }" class="fal fa-chevron-up"></i></div>
        <v-expand-transition>
          <div v-if="openSections.indexOf('status') > -1" class="filter-tags2">
            <div class="filter-tag" @click.stop="statusUpdate(item)"
              v-for="(item, i) in ['recent', 'vob', 'qualified', 'waitlist', 'unqualified', 'lost', 'admitted', 'discharged', 'archive']"
              :key="i"
              :class="[item, temp_status_q.includes(item) ? 'active' : null, tempFilters[tempFilters.findIndex((item) => item.name == 'status')] && tempFilters[tempFilters.findIndex((item) => item.name == 'status')].value.includes(item) ? 'active' : null]">
              {{ item }}</div>
          </div>
        </v-expand-transition>
      </div>


      <div class="filter-section">
        <div @click="openSection('source')" class="f-section-title">Source Filter <i
            :class="{ 'open': openSections.indexOf('source') > -1 }" class="fal fa-chevron-up"></i></div>
        <v-expand-transition>
          <div v-if="openSections.indexOf('source') > -1" class="filter-tags2">
            <div class="filter-tag" @click.stop="sourceUpdate(item)"
              v-for="(item, i) in ['google_ads', 'google_my_business', 'organic', 'rehabpath', 'direct', 'psychology_today']" :key="i"
              :class="[item, temp_source_q.includes(item) ? 'active' : null, tempFilters[tempFilters.findIndex((item) => item.name == 'source')] && tempFilters[tempFilters.findIndex((item) => item.name == 'source')].value.includes(item) ? 'active' : null]">
              {{ item.replaceAll('_', ' ') }}</div>
          </div>
        </v-expand-transition>
      </div>

      <div class="filter-section">
        <div @click="openSection('insurance')" class="f-section-title">Insurance Filter <i
            :class="{ 'open': openSections.indexOf('insurance') > -1 }" class="fal fa-chevron-up"></i></div>
        <v-expand-transition>
          <div v-if="openSections.indexOf('insurance') > -1" class="filter-tags2">
            <div class="filter-tag" @click.stop="insuranceUpdate(item)" v-for="(item, i) in availableInsurances" :key="i"
              :class="[item.value, temp_insurance_q.includes(item.value) ? 'active' : null, tempFilters[tempFilters.findIndex((item) => item.name == 'insurance')] && tempFilters[tempFilters.findIndex((item) => item.name == 'insurance')].value.includes(item.value) ? 'active' : null]">
              {{ item.text && item.text.replaceAll('_', ' ') }}</div>
          </div>
        </v-expand-transition>

      </div>
      <div class="filter-section">
        <div @click="openSection('assignedUser')" class="f-section-title">Rep Filter <i
            :class="{ 'open': openSections.indexOf('assignedUser') > -1 }" class="fal fa-chevron-up"></i></div>
        <v-expand-transition>
          <div v-if="openSections.indexOf('assignedUser') > -1" class="filter-tags2">
            <div class="filter-tag" @click.stop="assignedUserUpdate(item)" v-for="(item, i) in availableUsers"
              :key="i"
              :class="[item.value, temp_assignedUser_q.includes(item.value) ? 'active' : null, tempFilters[tempFilters.findIndex((item) => item.name == 'assignedUser')] && tempFilters[tempFilters.findIndex((item) => item.name == 'assignedUser')].value.includes(item.value) ? 'active' : null]">
              {{ item.text && item.text.replaceAll('_', ' ') }}</div>
          </div>
        </v-expand-transition>

      </div>
      <div class="filter-section">
        <div @click="openSection('tag')" class="f-section-title">Tag Filter <i
            :class="{ 'open': openSections.indexOf('tag') > -1 }" class="fal fa-chevron-up"></i></div>
        <v-expand-transition>
          <div v-if="openSections.indexOf('tag') > -1" class="filter-tags2">
            <div class="filter-tag" @click.stop="tagUpdate(item.value)" v-for="(item, i) in availableTags" :key="i"
              :class="[item.value, temp_tag_q.includes(item.value) ? 'active' : null, tempFilters[tempFilters.findIndex((item) => item.name == 'tag')] && tempFilters[tempFilters.findIndex((item) => item.name == 'tag')].value.includes(item.value) ? 'active' : null]">
              {{ item.text && item.text.replaceAll('_', ' ') }}</div>
          </div>
        </v-expand-transition>

      </div>
      <div class="filter-section">
        <div @click="openSection('from')" class="f-section-title">Location Filter <i
            :class="{ 'open': openSections.indexOf('from') > -1 }" class="fal fa-chevron-up"></i></div>
        <v-expand-transition>
          <div v-if="openSections.indexOf('from') > -1" class="filter-tags2">
            <div class="filter-tag" @click.stop="fromUpdate(item)" v-for="(item, i) in ['call', 'form', 'callrail']" :key="i"
              :class="[item, temp_from_q.includes(item) ? 'active' : null, tempFilters[tempFilters.findIndex((item) => item.name == 'from')] && tempFilters[tempFilters.findIndex((item) => item.name == 'from')].value.includes(item) ? 'active' : null]">
              {{ item.replaceAll('_', ' ') }}</div>
          </div>
        </v-expand-transition>

      </div>
      <div class="filter-section">
        <div @click="openSection('page')" class="f-section-title">Good Lead 
          <v-checkbox style="margin-left:auto" :color="appColor" :ripple="false" :value="temp_goodLead || false" @change="goodLeadUpdate($event)"></v-checkbox>
        </div>
        

      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import {mapGetters, mapActions} from 'vuex';
import axios from 'axios';
export default {
  name: 'filterdrawer',
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    isOpen: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tempFilters: [],

      openSections: [],

      filterStartFormatted: '',
      filterStartDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      filterStartDateMenu: false,

      filterEndFormatted: '',
      filterEndDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      filterEndDateMenu: false,

      filterStartAdmitFormatted: '',
      filterStartAdmitDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      filterStartAdmitDateMenu: false,

      filterEndAdmitFormatted: '',
      filterEndAdmitDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      filterEndAdmitDateMenu: false,

      filterStartTimeFormatted: '',
      filterStartTime: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      filterStartTimeMenu: false,

      filterEndTimeFormatted: '',
      filterEndTime: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      filterEndTimeMenu: false,
      

      status_q: this.$route.query.status || [],
      temp_status_q: [],
      temp_source_q: [],
      temp_insurance_q: [],
      temp_assignedUser_q:[],
      temp_goodLead: false,
      temp_from_q:[],
      temp_tag_q:[],
      availableInsurances: []
    }
  },
  mounted() {
    this.tempFilters = JSON.parse(JSON.stringify(this.filters));
    this.temp_status_q = this.$route.query.status || [];
    this.temp_assignedUser_q = this.$route.query.assignedUser || [];
    this.temp_goodLead = this.$route.query.goodLead == 'true' || false;
    this.temp_tag_q = this.$route.query.tag || [];
    this.temp_from_q = this.$route.query.from || [];
    this.temp_source_q = this.$route.query.source || [];
    this.temp_insurance_q = this.$route.query.insurance || [];
    this.filterEndDate = this.$route.query.endDate && (new Date(new Date(this.$route.query.endDate) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    this.filterStartDate = this.$route.query.startDate && (new Date(new Date(this.$route.query.startDate) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    this.filterStartFormatted = this.$route.query.startDate;
    this.filterEndFormatted = this.$route.query.endDate;

    this.filterEndAdmitDate = this.$route.query.endAdmitDate && (new Date(new Date(this.$route.query.endAdmitDate) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    this.filterStartAdmitDate = this.$route.query.startAdmitDate && (new Date(new Date(this.$route.query.startAdmitDate) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    this.filterStartAdmitFormatted = this.$route.query.startAdmitDate;
    this.filterEndAdmitFormatted = this.$route.query.endAdmitDate;

    this.filterEndTime = this.$route.query.endTime && (new Date(new Date(this.$route.query.endTime) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    this.filterStartTime = this.$route.query.startTime && (new Date(new Date(this.$route.query.startTime) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    this.filterStartTimeFormatted = this.$route.query.startTime;
    this.filterEndTimeFormatted = this.$route.query.endTime;


    this.getInsurances();
  },
  computed: {
    ...mapGetters({
            user:"AUTH_USER",
            users:"AUTH_USERS"
        }),
        appColor(){
if(this.facility == null) return '#4db7c9';
return this.facility == 'oasis' ? '#a6855d' :'#4db7c9';
},
    availableUsers(){
        return this.users.filter((item)=>{
          return item.permissions.includes('leads') && item.archive != true;
        }).map((item)=>{
            return {text:`${item.first_name} ${item.last_name}`, value:item._id};
        })
    },
    availableTags() {

      return [
        { text: "VOB", value: "VOB" },
        { text: "Possible Insurance", value: "Possible Insurance" },
        { text: "Possible Private Pay", value: "Possible Private Pay" },
        { text: "Medicaid/Medicare", value: "Medicaid/Medicare" },
        { text: "Referred Out", value: "Referred Out" },
        { text: "Solicitation", value: "Solicitation" },
        { text: "Wrong Number", value: "Wrong Number" },
        { text: "Adolescent", value: "Adolescent" },
        { text: "Alumni", value: "Alumni" },
        { text: "Call Dropped", value: "Call Dropped" },
        { text: "Incoherent", value: "Incoherent" },
        { text: "Incoherent", value: "Incoherent" },
        { text: "Looking for Patient/Employee", value: "Looking for Patient/Employee" },
        { text: "Loved One", value: "Loved One" },
        { text: "Other", value: "Other" },
        { text: "Bad Insurance", value: "Bad Insurance" },
        { text: "Other Facility", value: "Other Facility" }

      ]
    },
  },

  watch: {
    isOpen(val) {
      if (val) {
        this.tempFilters = JSON.parse(JSON.stringify(this.filters));
        this.temp_status_q = this.$route.query.status || [];
        this.temp_source_q = this.$route.query.source || [];
        this.temp_insurance_q = this.$route.query.insurance || [];
        this.temp_assignedUser_q = this.$route.query.assignedUser || [];
        this.temp_tag_q = this.$route.query.tag || [];
        this.temp_from_q = this.$route.query.from || [];
        this.filterEndDate = this.$route.query.endDate && (new Date(new Date(this.$route.query.endDate) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.filterStartDate = this.$route.query.startDate && (new Date(new Date(this.$route.query.startDate) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.filterStartFormatted = this.$route.query.startDate;
        this.filterEndFormatted = this.$route.query.endDate;
        this.temp_goodLead = this.$route.query.goodLead == 'true' || false;

        this.filterEndAdmitDate = this.$route.query.endAdmitDate && (new Date(new Date(this.$route.query.endAdmitDate) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.filterStartAdmitDate = this.$route.query.startAdmitDate && (new Date(new Date(this.$route.query.startAdmitDate) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.filterStartAdmitFormatted = this.$route.query.startAdmitDate;
        this.filterEndAdmitFormatted = this.$route.query.endAdmitDate;

        this.filterEndTime = this.$route.query.endTime && (new Date(new Date(this.$route.query.endTime) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.filterStartTime = this.$route.query.startTime && (new Date(new Date(this.$route.query.startTime) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.filterStartTimeFormatted = this.$route.query.startTime;
        this.filterEndTimeFormatted = this.$route.query.endTime;
      }
    },
    status_q(val) {
      //this.$router.push({ query: { ...this.$route.query, status: val } });

    },
  },

  methods: {
    openSection(section) {
      if (this.openSections.indexOf(section) > -1) {
        this.openSections.splice(this.openSections.indexOf(section), 1)
      } else {
        this.openSections.push(section);
      }
    },
    async getInsurances() {
      try {
        let { data } = await axios.get('/api/insurance/all');
        this.availableInsurances = [...data.items.map((item) => {
          return {
            value: item.name,
            text: this.getInsuranceLabel(item.name)
          }
        })]
      } catch (e) {
        console.log(e);
      }
    },
    getInsuranceLabel(item) {
      let mapping = [
        { text: 'None', value: '' },
        { text: 'Aetna', value: 'aetna' },
        { text: 'Cigna', value: 'cigna' },
        { text: 'Medicaid', value: 'medicaid' },
        { text: 'UMR', value: 'umr' },
        { text: 'Humana', value: 'humana' },
        { text: 'Optum', value: 'optum' },
        { text: 'Beacon', value: 'beacon' },
        { text: 'United Health', value: 'united' },
        { text: 'BCBS', value: 'bcbs' },
        { text: 'HMSA', value: 'hmsa' },
        { text: 'HMAA', value: 'hmaa' },
        { text: 'UHA', value: 'uha' },
        { text: 'Private Pay', value: 'private_pay' }
      ]
      if (mapping[mapping.findIndex((item2) => item2.value == item)]) {
        return mapping[mapping.findIndex((item3) => item3.value == item)].text;
      } else {
        return item;
      }

    },
    applyFilter() {
      let applyObj = {}
      if (this.filterStartFormatted != '' && this.filterStartFormatted != '') {
        applyObj.dateRange = { startDate: this.filterStartFormatted, endDate: this.filterEndFormatted }
      } else {
        applyObj.dateRange = {}
      }

      if (this.filterStartAdmitFormatted != '' && this.filterStartAdmitFormatted != '') {
        applyObj.admitDateRange = { startAdmitDate: this.filterStartAdmitFormatted, endAdmitDate: this.filterEndAdmitFormatted }
      } else {
        applyObj.admitDateRange = {}
      }

      if (this.filterStartTimeFormatted != '' && this.filterStartTimeFormatted != '') {
        applyObj.timeRange = { startTime: this.filterStartTimeFormatted, endTime: this.filterEndTimeFormatted }
      } else {
        applyObj.timeRange = {}
      }
      if (this.temp_status_q.length > 0) {
        applyObj.status = this.temp_status_q;
      }
      if (this.temp_source_q.length > 0) {
        applyObj.source = this.temp_source_q;
      }
      if (this.temp_insurance_q.length > 0) {
        applyObj.insurance = this.temp_insurance_q;
      }
      if (this.temp_assignedUser_q.length > 0) {
        applyObj.assignedUser = this.temp_assignedUser_q;
      }
      if (this.temp_tag_q.length > 0) {
        applyObj.tag = this.temp_tag_q;
      }
      if (this.temp_from_q.length > 0) {
        applyObj.from = this.temp_from_q;
      }
      if (this.temp_goodLead) {
        applyObj.goodLead = this.temp_goodLead;
      }
      console.log(applyObj);
      this.$emit('updateFilter', applyObj)
    },
    statusUpdate(status) {
      this.$emit('filtersLoading', true);
      let idx = this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'status')] && this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'status')].value.indexOf(status);
      let arr = this.tempFilters.findIndex((item) => item.name == 'status') > -1 ? [...this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'status')] && this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'status')].value] : [] || []
      if (idx > -1) {
        arr.splice(idx, 1)

        this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'status')].value = arr
        this.temp_status_q = arr;
        //  this.$emit('updateFilter', {status:arr})

      } else {
        arr.push(status)

        this.temp_status_q = arr;
        let TF = this.tempFilters.findIndex((item) => item.name == 'status') > -1
        if (TF) {
          this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'status')].value = arr
        } else {
          this.tempFilters[0] = {
            name: 'status',
            type: 'string',
            operator: 'array',
            value: arr
          }
        }

        // this.$emit('updateFilter', {status:arr})
      }
    },
    sourceUpdate(source) {
      this.$emit('filtersLoading', true);
      let idx = this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'source')] && this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'source')].value.indexOf(source);
      let arr = this.tempFilters.findIndex((item) => item.name == 'source') > -1 ? [...this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'source')] && this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'source')].value] : [] || []
      if (idx > -1) {
        arr.splice(idx, 1)

        this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'source')].value = arr
        this.temp_source_q = arr;
        //  this.$emit('updateFilter', {source:arr})

      } else {
        arr.push(source)

        this.temp_source_q = arr;
        let TF = this.tempFilters.findIndex((item) => item.name == 'source') > -1
        if (TF) {
          this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'source')].value = arr
        } else {
          this.tempFilters[0] = {
            name: 'source',
            type: 'string',
            operator: 'array',
            value: arr
          }
        }

        // this.$emit('updateFilter', {source:arr})
      }
    },
    insuranceUpdate(insurance) {
      this.$emit('filtersLoading', true);
      let idx = this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'insurance_company')] && this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'insurance_company')].value.indexOf(insurance.value);
      let arr = this.tempFilters.findIndex((item) => item.name == 'insurance_company') > -1 ? [...this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'insurance_company')] && this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'insurance_company')].value] : [] || []
      if (idx > -1) {
        arr.splice(idx, 1)
        this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'insurance_company')].value = arr
        this.temp_insurance_q = arr;
        //  this.$emit('updateFilter', {insurance:arr})

      } else {
        arr.push(insurance.value)

        this.temp_insurance_q = arr;
        let TF = this.tempFilters.findIndex((item) => item.name == 'insurance_company') > -1
        if (TF) {
          this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'insurance_company')].value = arr
        } else {
          this.tempFilters[0] = {
            name: 'insurance_company',
            type: 'string',
            operator: 'array',
            value: arr
          }
        }

        // this.$emit('updateFilter', {insurance:arr})
      }
    },
    assignedUserUpdate(assignedUser) {
      this.$emit('filtersLoading', true);
      let idx = this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'assignedUser')] && this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'assignedUser')].value.indexOf(assignedUser.value);
      let arr = this.tempFilters.findIndex((item) => item.name == 'assignedUser') > -1 ? [...this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'assignedUser')] && this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'assignedUser')].value] : [] || []
      if (idx > -1) {
        arr.splice(idx, 1)
        this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'assignedUser')].value = arr
        this.temp_assignedUser_q = arr;
        //  this.$emit('updateFilter', {assignedUser:arr})

      } else {
        arr.push(assignedUser.value)
        this.temp_assignedUser_q = arr;
        let TF = this.tempFilters.findIndex((item) => item.name == 'assignedUser') > -1
        if (TF) {
          this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'assignedUser')].value = arr
        } else {
          this.tempFilters[0] = {
            name: 'assignedUser',
            type: 'string',
            operator: 'array',
            value: arr
          }
        }

        // this.$emit('updateFilter', {insurance:arr})
      }
    },
    tagUpdate(tag) {
      this.$emit('filtersLoading', true);
      let idx = this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'tag')] && this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'tag')].value.indexOf(tag);
      let arr = this.tempFilters.findIndex((item) => item.name == 'tag') > -1 ? [...this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'tag')] && this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'tag')].value] : [] || []
      if (idx > -1) {
        arr.splice(idx, 1)

        this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'tag')].value = arr
        this.temp_tag_q = arr;
        //  this.$emit('updateFilter', {tag:arr})

      } else {
        arr.push(tag)

        this.temp_tag_q = arr;
        let TF = this.tempFilters.findIndex((item) => item.name == 'tag') > -1
        if (TF) {
          this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'tag')].value = arr
        } else {
          this.tempFilters[0] = {
            name: 'tag',
            type: 'string',
            operator: 'array',
            value: arr
          }
        }

        // this.$emit('updateFilter', {tag:arr})
      }
    },
    fromUpdate(from) {
      this.$emit('filtersLoading', true);
      let idx = this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'from')] && this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'from')].value.indexOf(from);
      let arr = this.tempFilters.findIndex((item) => item.name == 'from') > -1 ? [...this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'from')] && this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'from')].value] : [] || []
      if (idx > -1) {
        arr.splice(idx, 1)

        this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'from')].value = arr
        this.temp_from_q = arr;
        //  this.$emit('updateFilter', {from:arr})

      } else {
        arr.push(from)

        this.temp_from_q = arr;
        let TF = this.tempFilters.findIndex((item) => item.name == 'from') > -1
        if (TF) {
          this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'from')].value = arr
        } else {
          this.tempFilters[0] = {
            name: 'from',
            type: 'string',
            operator: 'array',
            value: arr
          }
        }

        // this.$emit('updateFilter', {tag:arr})
      }
    },
    pageUpdate(page) {
      this.$emit('filtersLoading', true);
      let idx = this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'page')] && this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'page')].value.indexOf(page);
      let arr = this.tempFilters.findIndex((item) => item.name == 'page') > -1 ? [...this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'page')] && this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'page')].value] : [] || []
      if (idx > -1) {
        arr.splice(idx, 1)

        this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'page')].value = arr
        this.temp_page_q = arr;
        //  this.$emit('updateFilter', {page:arr})

      } else {
        arr.push(page)

        this.temp_page_q = arr;
        let TF = this.tempFilters.findIndex((item) => item.name == 'page') > -1
        if (TF) {
          this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'page')].value = arr
        } else {
          this.tempFilters[0] = {
            name: 'page',
            type: 'string',
            operator: 'array',
            value: arr
          }
        }

        // this.$emit('updateFilter', {tag:arr})
      }
    },
    goodLeadUpdate(goodLead) {
      this.$emit('filtersLoading', true);
        let TF = this.tempFilters.findIndex((item) => item.name == 'goodLead') > -1
        this.temp_goodLead = goodLead;
        if (TF) {
          
          this.tempFilters[this.tempFilters.findIndex((item) => item.name == 'goodLead')].value = goodLead
        } else {
          this.tempFilters.push({
            name: 'goodLead',
            type: 'boolean',
            operator: 'boolean',
            value: goodLead
          })
        }
      
    },
    updateFilter(filter, typ = 'date') {
      let type = filter.label == 'start' ? "Start" : "End"
      let d = `filter${type}Formatted`;
      if(typ == 'time'){
        d = `filter${type}TimeFormatted`
      }
      if(typ == 'admit'){
        d = `filter${type}AdmitFormatted`
      }
      this[d] = filter && moment(filter.value).format('MM/DD/YYYY')

    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  }
}

</script>
<style lang="scss">
.filter-tags2 {
  padding: 0px 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;

  .filter-tag {
    user-select: none !important;

    i {

      margin-right: 6px;
      transition: all .3s ease;
      height: 18px;
      width: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 14px;
      cursor: pointer;

      &:hover {
        background: #00000030;
      }
    }

    opacity: .3;
    cursor: pointer;
    transition:all .3s ease;

    &:hover {
      opacity: .7;
      filter: saturate(1)
    }

    filter:saturate(.5);

    &.active {
      opacity: 1;
      filter: saturate(1);
    }

    border-radius: 50px;
    height:25px;
    display:flex;
    align-items: center;
    padding:0px 15px 0px 15px;
    background:#808080;
    font-size:10pt;
    color:#fff;

    &.recent{
      background:#30838e;
    }
    &.qualified{
    background:#ff9900;
  }
  &.vob{
      background:#aa9821;
  }
  &.ready{
    background:#8314dd;
  }
  &.referred{
    background:#28b69e;
  }
  &.unqualified{
    background:#808080;
  }
  &.lost{
    background:#808080;
  }
  &.closed{
    background: #205cb5;
  }
  &.admitted{
    background:#ab4c84;
  }
  &.archived{
    background:#676668;
  }

    &.google_ads {
      background: #676668;
    }

    &.organic {
      background: #676668;
    }

    &.rehabpath {
      background: #676668;
    }

    &.direct {
      background: #676668;
    }

    &.psychology_today {
      background: #676668;
    }
  }
}

.filter-drawer {
  background: #fff;
  max-height: 100vh;
  position: fixed;
  width: 350px;
  padding-top: 70px;
  height: 100%;
  text-align: left;
  padding-left: 0;

  .filter-top {
    height: 45px;
    border-bottom: solid 1px #eaeaea;
    display: flex;
    align-items: center;
    padding: 0px 15px;
  }

  .filter-body {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height:100%;
    .filter-section {

      border-bottom: solid 1px #eaeaea;

      .f-section-title {
        font-size: 10pt;

        &:hover {
          background: #f9fafb;
          cursor: pointer;
        }

        padding-left: 15px;
        height:35px;
        padding-right:15px;
        display: flex;
        align-items: center;
        gap: 15px;

        i {
          transition: all .3s ease;
          margin-left: auto;

          &.open {
            transform: rotate(180deg);
          }
        }
      }

      .frow {
        padding: 0px 15px;
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
  }
}</style>