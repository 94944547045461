<template>
  <div class="inner-stage" style="text-align:left;">
    
    <div v-if="isLoaded" class="forms-container">
      <div style="margin-bottom:15px;">
        <div class="f-name"><i class="fad fa-file"></i>{{inventory ? 'Inventory': 'Forms'}} List </div><br>
        <v-btn :color="appColor" small dark depressed @click="$router.push('/forms/create')">Create New Form</v-btn>
        <!-- <v-select v-model="fac" outlined small compact dense :items="[{text:'Residential', value:'residential'},{text:'TLC', value:'tlc'}]" :color="appColor" style="max-width:250px" label="Facility"></v-select> -->
      </div>
      <div class="form-filters">
        <v-select style="max-width:200px" outlined v-model="form_frequency" :items="[{text:'All', value:'all'},{text:'Running', value:'running'}, {text:'Daily', value:'daily'},{text:'Weekly', value:'weekly'},{text:'Monthly', value:'monthly'},{text:'Bi-Monthly', value:'bi-monthly'}, {text:'Annually', value:'annual'}, {text:'As Needed', value:'as_needed'}]" label="frequency"></v-select>
        <v-select style="max-width:200px" outlined v-model="form_department" :items="[{text:'All', value:'all'}, {text:'RCT', value:'rct'},{text:'Food Service', value:'food service'},{text:'Medical', value:'medical'}]" label="Department"></v-select>
      </div>
      <v-client-table
        :perPage="50"
        :data="forms"
        :columns="columns"
        :options="{perPage:50}"
        >
        <span slot="h__name" >
            {{inventory ? 'Inventory' : 'Form'}} Name
        </span>
        <div slot="department" slot-scope="{ row }">
          <div v-if="typeof row.department == 'object'"><span  v-for="(item, i) in row.department" class="dep-tag" :class="row.department[i].split(' ')[0]" :key="i">{{ item }}</span></div>
          <div v-else class="dep-tag" :class="row.department.split(' ')">{{ row.department}}</div>
        </div>
        <div slot="name" style="text-align:left; display:flex; align-items:center;" slot-scope="{ row }">
          <div style="width:150px;">
              <div v-if="typeof row.department == 'object'"><span  v-for="(item, i) in row.department" class="dep-tag" :class="row.department[i].split(' ')[0]" :key="i">{{ item }}</span></div>
              <div v-else class="dep-tag" :class="row.department.split(' ')">{{ row.department}}</div>
            </div>
            <a @click="row.type != 'inventory' ? $router.push(`/forms/${row._id}`) : $router.push(`/inventory/${row._id}`)" style="white-space: nowrap; font-weight:bold;">{{row.name}} <div class="freq-tag">{{ row.frequency }}</div>
            
          </a>
        </div>
        
        </v-client-table>

      <!-- <div @click="$router.push(`/forms/${form._id}`)" class="form-item" v-for="(form, i) in forms" :key="i">
        <i style="color:var(--app-color);margin-right:10px; font-size:18pt;" class="fad fa-file"></i>
        <div class="form-name">{{ form.name }}</div>
      </div> -->

     

    </div>
    
    
    
    
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Users",
  data() {
    return {
      moment,
      isLoaded: false,
      forms:[],
      editUser:null,
      addUserLoading:false,
      errorMessage:'',
      form_frequency:'all',
      form_department:'all'
    };
  },
  mounted() {
    
    this.getForms();

    
  },
  
  methods: {
    ...mapActions({
      facility_set:'facility_set'
    }),
    async getForms() {
      let {data} = await axios.post(`/api/${this.facility == 'oasis' ? 'oasis/' : ''}forms/get_forms`, {filters:this.filters});
      this.forms = data.items.filter((item)=>{
        if(item.department == 'all' && !this.$route.path.includes('inventory')){
          return true;
        }
        return this.inventory ? 
        item.type == 'inventory' && (this.authUser.department != 'all' ? [].concat(item.department).some((item2)=>{return [].concat(this.authUser.department).includes(item2)}) : true) 
      : item.type != 'inventory' && (this.authUser.department != 'all' ? [].concat(item.department).some((item2)=>{return [].concat(this.authUser.department).includes(item2)})  : true)
      });
      this.isLoaded = true;
   
    },
  },
  computed:{
    filters(){
      let obj = {
        frequency: this.form_frequency,
        department: this.form_department
      }
      if(obj.frequency == 'all') delete obj.frequency;
      if(obj.department == 'all') delete obj.department;
      return obj;
    },
appColor(){
if(this.facility == null) return '#4db7c9';
return this.facility == 'oasis' ? '#a6855d' :'#4db7c9';
},
    ...mapGetters({
      authUser:'AUTH_USER',
      facility:'FACILITY'
    }),
    inventory(){
      return this.$route.path == '/inventory'
    },
    fac:{
      get(){
        return this.facility || '';
      },
      set(v){
        console.log(v);
        this.facility_set(v);
      }
    },
    page(){
      
      return this.$route.path;
    },
    
    columns(){
      return [
        'name',
        
      ]
    }
  },
  watch:{
    form_frequency(v){
      this.isLoaded = false;
      this.forms = [];
      this.getForms();
    },
    form_department(v){
      this.isLoaded = false;
      this.forms = [];
      this.getForms();
    },
    page(v){
      this.isLoaded = false;
      this.forms = [];
      this.getForms();
    }
  }
 
};
</script>
<style scoped lang="scss">
@import "@/assets/scss.scss";
.f-name{
        font-size:15pt;
        
       font-weight: bold;
        
        i{
            color:var(--app-color);
            margin-right:10px;
        }
        
    }
.inner-stage{
  display:flex;
  justify-content: center;
}

.forms-container{
  display:flex;
  flex-direction: column;
  width:100%;
  padding:16px;
  gap:15px;
.form-item{
  cursor: pointer;
  max-width:100%;
  text-align: left;
  width:100%;
  height:100px;
  display:flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: $box-shadow;
  background:#fff;
  padding:25px;
  transition:all .3s ease;
  &:hover{
    box-shadow:rgba(23, 48, 73, 0.32) 0px 7px 12px;
  }
  i{
    margin-right:20px !important;
  }
  .form-name{
    font-size:15pt;
    font-weight: bold;
  }
}

}
.form-filters{
  margin:10px 0px;
  display:inline-flex;
  gap: 10px;
}
.dep-tag{
  background:#808080;
  color:#fff;
  padding:5px 10px;
  border-radius:100px;
  font-size:10pt;
  margin-right:5px;
  margin-bottom:5px;
  display:inline-block;
  &.rct{
    background:#5b1987;
  }
  &.medical{
    background:#b72132;
  }
  &.food{
    background:#3ba674;
  }
  &.all{
    background:#40b5cc;
  }


}
.freq-tag{
  font-size:.8em;
  color:darken(#eaeaea, 50%);
  background:#eaeaea;
  border-radius: 100px;
  display:inline-block;
  padding:5px 10px;
  margin-left:25px;
}
</style>