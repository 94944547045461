<template>
  <div class="inner-stage" >
    <v-dialog width="1200" v-model="manageUserOpen">
      <manage-user :addUser="editUser == null || addUser" :editUser="editUser" @close="addUser = false, manageUserOpen = false, getUsers()" />
    </v-dialog>
    <transition enter-active-class="fadeInUp" leave-active-class="fadeOut">
      <div v-if="!isLoaded"  class="loaderContainer animated-fast">
        <div  class="wrapper">
          <div class="wrapper-cell">
            
            <div class="text">
              <div class="text-line" style="height:50px"></div>
              <div class="text-line" style="max-width:100px;margin-top:40px;"></div>
              <div class="text-line"></div>
              <div class="text-line"></div>
            </div>
          </div>
          <div class="wrapper-cell">
            
            <div class="text">
              <div class="text-line"></div>
              <div class="text-line"></div>
              <div class="text-line"></div>
              <div class="text-line"></div>
            </div>
          </div>
          <div class="wrapper-cell">
            
            <div class="text">
              <div class="text-line"></div>
              <div class="text-line"></div>
              <div class="text-line"></div>
              <div class="text-line"></div>
            </div>
          </div>
          <div class="wrapper-cell">
           
            <div class="text">
              <div class="text-line"></div>
              <div class="text-line"></div>
              <div class="text-line"></div>
              <div class="text-line"></div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div v-if="false" class="users-container">
      <div class="user-item " :class="{'add-user':editUser == null}">
        <div class="add" @click="editUser = {}" >
          <i class="fal fa-plus"></i>
          
        </div>
        
        
      </div>
      <div class="user-item" v-for="(user,i) in users" :key="i">
        
        <div v-if="editUser == null || editUser._id != user._id"><div class="user-role">{{user.role}}</div></div>
        <div v-if="editUser == null || editUser._id != user._id" class="user-edit" @click="startEdit(user._id)"><i class="fa fa-pencil"></i> </div>
        <div v-if="editUser == null || editUser._id != user._id" class="user-name">{{user.first_name}} {{user.last_name}} </div>
        <div v-if="editUser == null || editUser._id != user._id" class="users-dets">{{user.email}} </div>
      <v-expand-transition>
        <div v-if="editUser != null && editUser._id == user._id"> 
          <v-select :color="appColor" :items="['admin', 'basic', 'forms', 'forms + basic']" label="User Role" outlined v-model="editUser.role"></v-select>
          <v-select :color="appColor" :items="[{text:'RCT', value:'rct'},{text:'Food Service', value:'food service'}, {text:'Medical', value:'medical'},{text:'Clinical', value:'clinical'}, {text:'Admissions', value:'admissions'}, {text:'All', value:'all'}]" label="Department" outlined v-model="editUser.department"></v-select>
          <div style="display:flex;">
            <v-text-field :color="appColor" label="First Name" outlined v-model="editUser.first_name"></v-text-field>
            <v-text-field :color="appColor" style="margin-left:10px" label="Last Name" outlined v-model="editUser.last_name"></v-text-field>
          </div>
          <v-text-field :color="appColor" label="Email" outlined v-model="editUser.email"></v-text-field>
          <div style="display:flex; align-items:center;">
            <div style="margin-left:auto; display:flex; gap:10px;">
          <v-btn @click="updateUser(user)" :color="appColor" dark depressed small>Save</v-btn><v-btn v-if="user._id != authUser.id" color="#ff0000" dark @click="deleteUser" depressed small>Delete</v-btn><v-btn @click="editUser = null"  dark depressed small>Cancel</v-btn>
              
        </div>
          </div>
        </div>
      </v-expand-transition>
        
      </div>
    </div>
    <div v-if="isLoaded" class="users-container">
      <div style="margin-bottom:15px;">
        <div class="f-name"><i class="fad fa-user"></i>User List </div><br>
        <div style="display:flex">
        <v-btn :color="appColor" small dark depressed @click="editUser = {permissions:[]}, addUser = true; manageUserOpen = true; editUser.password = '123'">Add New User</v-btn>
        <v-btn v-if="role == 'admin'" :color="archivedUsers ? 'var(--app-color)' : '#808080'" style="margin-left:auto;" small dark depressed @click="archivedUsers = !archivedUsers">Archived Users</v-btn>
        <!-- <v-select v-model="fac" outlined small compact dense :items="[{text:'Residential', value:'residential'},{text:'TLC', value:'tlc'}]" :color="appColor" style="max-width:250px" label="Facility"></v-select> -->
      </div>
      </div>
    <v-client-table
            
            ref="my-table"
            
            :data="users"
            :columns="columns"
            :options="{perPage:50}"
            
            >
      <div slot="name" slot-scope="{ row }">
      <a style="display:flex;
       align-items:center" @click="editUser = row, manageUserOpen = true">{{ row.name }} <v-btn  icon style="margin-left:auto;" @click.stop="$router.push(`/users/user/${row._id}`)" :color="appColor" small text><i class="fad fa-share-square" style="margin-right:0px;" ></i></v-btn></a>
      </div>
      <div slot="permissions" slot-scope="{ row }">
        <span style="margin-right:10px;" v-for="(item, i) in roleOptions" :key="i"><i v-if="row.permissions.includes(item.value)" style="margin-right:5px; color:var(--app-color)" class="fad fa-check-square"></i><i v-else style="margin-right:5px; color:#ff0000" class="fad fa-times-square"></i>{{ item.text }}</span>
      </div>
      <div slot="department" slot-scope="{ row }">
        <span v-if="typeof row.department == 'string'">{{ row.department }}</span>
        <span v-if="typeof row.department == 'object'">{{ row.department.join(',') }}</span>
      </div>
    </v-client-table>
    
    </div>
    
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import manageUser from '../components/manageUser.vue';
export default {
  components: { manageUser },
  name: "Users",
  data() {
    return {
      moment,
      isLoaded: false,
      users:[],
      editUser:null,
      addUserLoading:false,
      errorMessage:'',
      manageUserOpen:false,
      savingUser:false,
      addUser:false,
      archivedUsers:false
    };
  },
  mounted() {
    
    this.getUsers();

    
  },
  watch:{
    archivedUsers(v){
      this.getUsers();
    }
  },
  methods: {
    async updateUser(user){
      console.log('ey')
      this.savingUser = true;
      this.errorMessage = '';
      this.addUserLoading = true;
      let post = this.editUser;
      delete post.password;
      try{
        let {data} = await axios.put(`/api/users/edit/${user._id}`, post);
        this.addUserLoading = false;
        this.editUser = null;
        this.getUsers();
        this.savingUser = false;
        this.manageUserOpen = false;
      }catch(e){
        this.addUserLoading = false;
        if(e.response){
          this.errorMessage = e.response.data.message;
        }
        
        this.savingUser = false;
      }
    },
    async deleteUser(){
      this.errorMessage = '';
      try{
        let {data} = await axios.post('/api/users/archive', {id:this.editUser._id});
        this.addUserLoading = false;
        this.editUser = null;
        this.getUsers();
      }catch(e){
        this.addUserLoading = false;
      }
    },
    async submitAddUser(){
      this.errorMessage = '';
      this.addUserLoading = true;
      let post = this.editUser;
      try{
        let {data} = await axios.post('/api/users/add', post);
        this.addUserLoading = false;
        this.editUser = null;
        this.getUsers();
        this.manageUserOpen = false;
      }catch(e){
        this.addUserLoading = false;
        if(e.response){
          this.errorMessage = e.response.data.message;
        }
       
      }
    },
    startEdit(id){
      this.editUser = JSON.parse(JSON.stringify(this.users.filter((item)=>item._id == id)[0]));
    },
    async getUsers() {
      let {data} = await axios.get(`/api/users/get_users?archived=${this.archivedUsers}`);
      this.users = data.items.map((item)=>{
        return {
          ...item,
          name: item.first_name + ' ' + item.last_name
        }
      });
      this.isLoaded = true;
   
    },
  },
  computed:{
appColor(){
if(this.facility == null) return '#4db7c9';
return this.facility == 'oasis' ? '#a6855d' :'#4db7c9';
},
    ...mapGetters({
      authUser:'AUTH_USER',
      facility:'FACILITY',
      user:'AUTH_USER'
    }),
    columns(){
      return [
        'name',
        'email',
        'department',
        'role',
        'permissions'
      ]
    },
    role(){
      return this.user.role;
    },
    roleOptions(){
      return [
        {text:'Dashboard', value:'dashboard'},
        {text:'Leads', value:'leads'},
        {text:'Reminders', value:'reminders'},
        {text:'Users', value:'users'},
        {text:'Forms', value:'forms'},
        {text:'Inventory', value:'inventory'},
        {text:'Bed Chart', value:'bed_chart'}
      ]
    }
  }
 
};
</script>
<style lang="scss">
.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label{
      text-transform: none !important;
      letter-spacing: 0px !important;
      font-weight: normal !important
    }
</style>
<style scoped lang="scss">
@import "@/assets/scss.scss";
.f-name{
        font-size:15pt;
        
       font-weight: bold;
        
        i{
            color:var(--app-color);
            margin-right:10px;
        }
        
    }
.error-message{

}
@import "@/assets/scss.scss";
.inner-stage{
  display:flex;
  justify-content: center;
}

.users-container{
  display:flex;
  flex-direction: column;
  width:100%;
  padding:15px;
  text-align: left;
  .user-item{
    background: #fff;
    box-shadow: $box-shadow;
    border-radius: 5px;
    font-size:12pt;
    border:solid 1px #f1f1f1;
    margin-bottom:25px;
    padding:15px;
    display:grid;
    grid-template-columns:1fr;
    grid-gap:15px;
    flex-direction: column;
    max-width:500px;
    width:100%;
    text-align:left;
    position:relative;
    &:hover{
      .user-edit{
        opacity: 1;
      }
    }
    .user-edit{
      position:absolute;
      transition:all .3s ease;
      right:10px;
      top:10px;
      height:35px;
      width:35px;
      font-size:10pt;
      color:#808080;
      opacity: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;
      @include media-query($small) {
          opacity:1;
      }
      &:hover{
        transform:scale(1.1,1.1);
        background:#f1f1f1;
      }
      &:active{
        transform:none;
        transition:none;
      }
    }
    .user-name{
      font-weight: bold;
    }
    .user-role{
      background:#eaeaea;
      display:inline-block;
      border-radius: 100px;
      padding:4px 10px;
      font-size:9pt;
      text-transform: uppercase;
      letter-spacing: 2px;
      color:#808080;
    }
    .users-dets{
      font-size:.8em;
      color: #808080
    }
  }
  .add-user{
    display:flex;
    align-items: center;
    justify-content: center;
    transition:all .3s ease;
    &:hover{
      cursor: pointer;
      transform:scale(1.05,1.05);
      .add{
        i{
          color:var(--app-color);
        }
        color:var(--app-color)
      }
    }
    &:active{
      transition:none;
      transform: none;
    }
    
  }
}
.add{
      display:flex ;
     height:100%;
     width:100%;
     justify-content: center;
     align-items: center;
      i{
        font-size:20pt;
        color:#aeaeae;
      }
      color:#aeaeae;
    }
.role-check-box{
  flex:1;
  flex-basis:50%;
  @include media-query($small){
    flex-basis: 100%;
  }
}
.table-bordered tbody tr td{
  overflow-x:hidden;
}
</style>